import axios from '../../utils/axios';

export async function getList(params?: any) {
  
  return axios.get(`${localStorage.getItem("ketoPublicUrl")}/relation-tuples`, {
    params: { ...params },
  });
}
export async function addMultipleRelationship(payload: any) {
  
  return axios.patch(
    `${localStorage.getItem("ketoAdminUrl")}/admin/relation-tuples`,
    payload
  );
}

export async function deleteMultipleRelationship(payload: any) {
  
  return axios.patch(
    `${localStorage.getItem("ketoAdminUrl")}/admin/relation-tuples`,
    payload?.arrRelationTuple
  );
}

export async function addRelationship(payload: any) {
  
  return axios.put(
    `${localStorage.getItem("ketoAdminUrl")}/admin/relation-tuples`,
    payload
  );
}

export async function delRelationship(params: any) {
  
  return axios.delete(`${localStorage.getItem("ketoAdminUrl")}/admin/relation-tuples`, {
    params: { ...params },
  });
}
