/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  MenuItem,
  MenuList,
  Option,
  Radio,
  RadioGroup,
  Toast,
  ToastBody,
  ToastTitle,
  makeStyles,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store/store";
import { arrObjectEomAcp, arrRelation } from "../../utils/data";
import LoadingGlobalComp from "../Loading/LoadingGlobal";

import Autocomplete from "react-autocomplete";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  addMultipleRelationshipAsync,
  addRelationshipAsync,
  getListAsync,
} from "../../store/ManageRelationship";
import { changeVisibleSpinner } from "../../store/NavSlice";
import BackSvg from "./../../assets/back.svg";
import AddSvg from "./../../assets/add-new.svg";
import NextSvg from "./../../assets/next-paging.svg";
import EmptySvg from "../../assets/nodata.svg";
import AcceptSvg from "../../assets/accept.svg";
import DismissSvg from "../../assets/Dismiss.svg";

const useStyles = makeStyles({
  // these styles wrap the value text within the dropdown button and cause it to truncate
  truncatedText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const FormInsertComp = (props: any) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [object, setObject] = useState<any>(
    props?.type === "insert" ? [] : undefined
  );
  const [relation, setRelation] = useState<any>(undefined);
  const [subjectId, setSubjectId] = useState<any>();

  const [objectSubjectSet, setObjectSubjectSet] = useState<any>(undefined);
  const [relationSubjectSet, setRelationSubjectSet] = useState<any>(undefined);

  const [flagAddSubjectSet, setFlagAddSubjectSet] = useState<boolean>(false);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const [arrObject, setArrObject] = useState<any>([]);

  const [arrObjectSelected, setArrObjectSelected] = useState<any>([]);
  const [arrObjectSelectedBack, setArrObjectSelectedBack] = useState<any>([]);

  const [arrObjectChecked, setArrObjectChecked] = useState<any>([]);

  const [flagAddNewObject, setFlagAddNewObject] = useState<boolean>(false);

  const [textInput, setTextInput] = useState<any>("");

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { dispatchToast } = useToastController(toasterId);

  const notify = (msg: any, type: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>Notification</ToastTitle>
        <ToastBody>{msg}</ToastBody>
      </Toast>,
      { timeout: 5000, intent: type }
    );
  };

  const handleAdd = async () => {
    dispatch(changeVisibleSpinner(true));
    let objectCopy = arrObjectSelectedBack?.filter(
      (item: any) => item !== "all"
    );

    let newPay: any = [];

    for (let i = 0; i < objectCopy?.length; i++) {
      let a = await checkDuplicateRelations(
        flagAddSubjectSet
          ? {
              namespace: id,
              object: objectCopy?.[i],
              relation: relation,
              subject_set: {
                namespace: id,
                object: objectSubjectSet,
                relation: relationSubjectSet,
              },
            }
          : {
              namespace: id,
              object: objectCopy?.[i],
              relation: relation,
              subject_id: subjectId,
            }
      );

      if (!a) {
        newPay.push({
          action: "insert",
          relation_tuple: flagAddSubjectSet
            ? {
                namespace: id,
                object: objectCopy?.[i],
                relation: relation,
                subject_set: {
                  namespace: id,
                  object: objectSubjectSet,
                  relation: relationSubjectSet,
                },
              }
            : {
                namespace: id,
                object: objectCopy?.[i],
                relation: relation,
                subject_id: subjectId,
              },
        });
      }
    }

    if (newPay?.length > 0 && objectCopy?.length > 0) {
      try {
        const res = await dispatch(
          // @ts-ignore
          addMultipleRelationshipAsync(newPay)
        ).unwrap();
        if (res?.status === 204) {
          notify("Insert relationships successfully", "success");
          setTimeout(() => {
            navigate(`/relationship/${id}`);
            // @ts-ignore
            dispatch(changeVisibleSpinner(false));
          }, 1000);
        } else {
          notify("Insert relationships failed", "error");
          setTimeout(() => {
            dispatch(changeVisibleSpinner(false));
          }, 1000);
        }
      } catch (e) {
        console.error(e);
        notify("Error", "error");
        setTimeout(() => {
          dispatch(changeVisibleSpinner(false));
        }, 1000);
      }
    } else {
      notify("Duplicate all relationships", "error");
      setTimeout(() => {
        dispatch(changeVisibleSpinner(false));
      }, 1000);
    }
  };

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: 1,
          value: `Relationship ${id}`,
          path: "./relationship/" + id,
        },
        {
          key: 2,
          value: `Insert`,
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, [id]);

  useEffect(() => {
    (async function () {
      try {
        const a: any = localStorage.getItem("arrObject");
        setArrObject(a.split(",") ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const checkBtnSave = () => {
    let a = false;
    if (visibleSpinner) {
      a = true;
    }
    if (flagAddSubjectSet && (!objectSubjectSet || !relationSubjectSet)) {
      a = true;
    }
    if (!flagAddSubjectSet && !subjectId) {
      a = true;
    }
    if (arrObjectSelectedBack?.length === 0) {
      a = true;
    }
    return a;
  };

  const checkDuplicateRelations = async (payload: any) => {
    let newPay: any = {};

    if (payload?.subject_set?.object) {
      newPay = {
        namespace: id,
        object: payload?.object,
        relation: payload?.relation,
        "subject_set.namespace": payload?.subject_set?.namespace,
        "subject_set.object": payload?.subject_set?.object,
        " subject_set.relation": payload?.subject_set?.relation,
      };
    } else {
      newPay = payload;
    }
    let a = false;
    //@ts-ignore
    const res = await dispatch(getListAsync(newPay)).unwrap();
    if (res?.relation_tuples?.length > 0) {
      a = true;
    }

    return a;
  };

  const pickItem = (item: any, type: string) => {
    let items =
      type === "add"
        ? arrObjectSelected.slice()
        : arrObjectSelectedBack?.slice(); // copy
    let index = items.findIndex((x: any) => x === item);
    if (index < 0) {
      items.push(item);
    } else {
      items.splice(index, 1);
    }
    if (type === "add") {
      setArrObjectSelected(items);
    } else {
      setArrObjectSelectedBack(items);
    }
  };

  const handleAddNewObject = () => {
    if (textInput && textInput !== "") {
      const a: any = localStorage.getItem("arrObject");
      let b = a.split(",") ?? [];
      let c = arrObject?.slice();

      if (!b?.includes(textInput)) {
        b?.unshift(textInput);
        localStorage.setItem("arrObject", b);
      }
      if (!arrObject?.includes(textInput)) {
        c?.unshift(textInput);
        setArrObject(c);
      }

      setFlagAddNewObject(false);
      setTextInput("");
    }
  };

  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}

      <div className="div-form-storage">
        <div className="flex w-full justify-between">
          <div className="w-1/3" style={{ marginRight: "35px" }}>
            <p className="mb-2">Namespaces</p>
            <Dropdown
              placeholder="Select schema"
              className="div-dropdown"
              value={id}
              disabled
            >
              {[]?.map((item: any) => (
                <Option value={item} key={item?.id}>
                  {item?.id}
                </Option>
              ))}
            </Dropdown>

            <p className="mb-2 mt-6">Relation</p>
            <div className="autocomplete-wrapper div-dropdown">
              <Autocomplete
                inputProps={{
                  placeholder: "Select relation",
                }}
                value={relation}
                items={arrRelation}
                getItemValue={(item) => item}
                shouldItemRender={(item: any, val: any) => {
                  return item.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                }}
                renderMenu={(item) => <div className="dropdown">{item}</div>}
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${isHighlighted ? "selected-item" : ""}`}
                  >
                    {item}
                  </div>
                )}
                onChange={(event: any, val: any) => setRelation(val)}
                onSelect={(val: any) => setRelation(val)}
              />
            </div>
            <RadioGroup
              value={flagAddSubjectSet ? "subject_set" : "subject_id"}
              onChange={(_, data) => {
                setFlagAddSubjectSet(
                  data?.value === "subject_set" ? true : false
                );
              }}
              layout="horizontal"
              className="mb-2 mt-6"
            >
              <Radio value="subject_id" label="Subject id" />
              <Radio value="subject_set" label="Subject set" />
            </RadioGroup>
            {flagAddSubjectSet ? (
              <>
                <p className="mb-2">
                  Namespaces <span style={{ color: "red" }}>*</span>
                </p>
                <Dropdown
                  placeholder="Select schema"
                  className="div-dropdown"
                  value={id}
                  disabled
                >
                  {[]?.map((item: any) => (
                    <Option value={item} key={item?.id}>
                      {item?.id}
                    </Option>
                  ))}
                </Dropdown>

                <p className="mb-2 mt-6">
                  Object <span style={{ color: "red" }}>*</span>
                </p>
                <div className="autocomplete-wrapper div-dropdown">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Select object",
                    }}
                    value={objectSubjectSet}
                    items={arrObject}
                    getItemValue={(item) => item}
                    shouldItemRender={(item: any, val: any) => {
                      return (
                        item.toLowerCase().indexOf(val.toLowerCase()) !== -1
                      );
                    }}
                    renderMenu={(item) => (
                      <div className="dropdown">{item}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    onChange={(event: any, val: any) =>
                      setObjectSubjectSet(val)
                    }
                    onSelect={(val: any) => setObjectSubjectSet(val)}
                  />
                </div>

                <p className="mb-2 mt-6">
                  Relation <span style={{ color: "red" }}>*</span>
                </p>
                <div className="autocomplete-wrapper div-dropdown">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Select relation",
                    }}
                    value={relationSubjectSet}
                    items={arrRelation}
                    getItemValue={(item) => item}
                    shouldItemRender={(item: any, val: any) => {
                      return (
                        item.toLowerCase().indexOf(val.toLowerCase()) !== -1
                      );
                    }}
                    renderMenu={(item) => (
                      <div className="dropdown">{item}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    onChange={(event: any, val: any) =>
                      setRelationSubjectSet(val)
                    }
                    onSelect={(val: any) => setRelationSubjectSet(val)}
                  />
                </div>
              </>
            ) : (
              <>
                <p className="mb-2">
                  Subject Id <span style={{ color: "red" }}>*</span>
                </p>
                <Input
                  placeholder="Enter subject id"
                  className="div-dropdown"
                  value={subjectId}
                  onChange={(ev: any, data: any) => {
                    setSubjectId(data?.value);
                  }}
                />
              </>
            )}
          </div>
          <Divider vertical />
          <div className="w-2/3" style={{ marginLeft: "35px" }}>
            <div className="flex items-center justify-between">
              <div className="w-2/5">
                <p className="mb-2">Object</p>
                <div className="div-menu-list-select-object">
                  <MenuList className="w-full !max-w-full">
                    <MenuItem className="!max-w-full">
                      {!flagAddNewObject ? (
                        <span
                          className="flex"
                          onClick={() => setFlagAddNewObject(true)}
                        >
                          <img src={AddSvg} alt="empty" className="mr-1" />
                          Add new object
                        </span>
                      ) : (
                        <Input
                          className="mb-2 div-dropdown"
                          placeholder="Enter new object"
                          onChange={(ev, data) => setTextInput(data?.value)}
                          contentAfter={
                            <>
                              <img
                                src={AcceptSvg}
                                alt="empty"
                                className="img-svg-icon-small mr-2"
                                onClick={() => handleAddNewObject()}
                              />
                              <img
                                src={DismissSvg}
                                alt="empty"
                                className="img-svg-icon-small"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  setFlagAddNewObject(false);
                                  setTextInput("");
                                }}
                              />
                            </>
                          }
                        />
                      )}
                    </MenuItem>
                    {arrObject?.length > 0 && (
                      <MenuItem className="!max-w-full">
                        <Checkbox
                          checked={
                            arrObjectSelected?.length === arrObject?.length
                              ? true
                              : arrObjectSelected?.length === 0
                              ? false
                              : "mixed"
                          }
                          onChange={(_ev, val) => {
                            if (val?.checked) {
                              setArrObjectSelected(arrObject);
                            } else {
                              setArrObjectSelected([]);
                            }
                          }}
                        />
                        Select all
                      </MenuItem>
                    )}

                    {arrObject?.map((item: any) => {
                      return (
                        <MenuItem className="!max-w-full flex">
                          <Checkbox
                            onChange={(e: any, data: any) => {
                              pickItem(item, "add");
                            }}
                            checked={arrObjectSelected?.includes(item)}
                          />
                          {item}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </div>
              </div>

              <div className="w-1/5 text-center">
                <Button
                  className="divBtnPaginationAction !mr-3 !h-[30px]"
                  appearance="subtle"
                  size="medium"
                  icon={
                    <img
                      className="img-svg-icon-paging"
                      src={BackSvg}
                      alt="back"
                    />
                  }
                  disabled={arrObjectSelectedBack?.length === 0}
                  onClick={() => {
                    let a = [...arrObjectSelectedBack, ...arrObject];
                    let b = arrObjectSelectedBack;
                    let c = arrObjectChecked.filter(
                      (item: any) => !b.includes(item)
                    );

                    setArrObjectChecked(c);
                    setArrObject(a);
                    setArrObjectSelectedBack([]);
                  }}
                />
                <Button
                  className="divBtnPaginationAction !h-[30px]"
                  appearance="subtle"
                  size="medium"
                  icon={
                    <img
                      className="img-svg-icon-paging"
                      src={NextSvg}
                      alt="next"
                    />
                  }
                  style={{ marginRight: "8px" }}
                  disabled={arrObjectSelected?.length === 0}
                  onClick={() => {
                    let a = [...arrObjectSelected, ...arrObjectChecked];
                    let b = arrObjectSelected;
                    let c = arrObject.filter((item: any) => !b.includes(item));

                    setArrObject(c);
                    setArrObjectChecked(a);
                    setArrObjectSelected([]);
                  }}
                />
              </div>

              <div className="w-2/5">
                {arrObjectChecked?.length > 0 ? (
                  <div className="div-menu-list-select-object !mt-[27px]">
                    <MenuList>
                      {arrObjectChecked?.map((item: any) => {
                        return (
                          <MenuItem className="!max-w-full">
                            <Checkbox
                              onChange={(e: any, data: any) => {
                                pickItem(item, "back");
                              }}
                              checked={arrObjectSelectedBack?.includes(item)}
                            />
                            {item}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </div>
                ) : (
                  <center>
                    <img src={EmptySvg} alt="empty" />
                    <p className="mt-3 text-neutral-50">No selected object</p>
                  </center>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex absolute bottom-4 right-4 gap-x-2">
        <Button
          appearance="outline"
          className="h-8 !bg-ink-400 border border-white/0.03"
          onClick={() => navigate(`/relationship/${id}`)}
          disabled={visibleSpinner}
        >
          {t("Cancel")}
        </Button>

        <Button
          appearance="outline"
          className="h-8 !bg-primary-500 border border-white/0.03 !text-black"
          disabled={checkBtnSave()}
          onClick={() => handleAdd()}
        >
          {t("Insert")}
        </Button>
      </div>
    </div>
  );
};

export default FormInsertComp;
