import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getListUser,
  deleteUser,
  getSchemas,
  addUser,
  updateUser,
  deleteManyUser,
  createRecoveryLink,
  createRecoveryCode,
  setAdmin,
  setStaff,
  setUser,
  getRolesUser,
  getInfor,
  getInforUserById,
  getSchemasByIdSchema,
  applyAdminRoles,
  applyUserRoles,
} from "./../services/ManageUser/ManageUser";

export interface SerializedError {
  name?: string;
  message?: string;
  stack?: string;
  code?: string;
}

var error: SerializedError = {};

export interface ManageUserState {
  page: number;
  limit: number;
  total: number;
  danhSach: Array<any>;

  inforUser: any;
  status: string;

  loading: string;
  currentRequestId: any;
  error: SerializedError;
  flagRemoveSelected: boolean;
  flagRecoverySelected: boolean;
  flagRefresh: boolean;
}

const initialState: ManageUserState = {
  page: 0,
  limit: 10,
  total: 0,
  danhSach: [],
  status: "loading",
  inforUser: {},

  loading: "idle",
  currentRequestId: undefined,
  error: error,
  flagRemoveSelected: false,
  flagRecoverySelected: false,
  flagRefresh: false,
};

export const getIdentitiesAsync = createAsyncThunk<
  null, // Returned,
  { payload: any },
  {
    state: { manageuser: { loading: string; currentRequestId: string } };
  }
>("getIdentities", async (payload: any, { getState, requestId }) => {
  //const { currentRequestId, loading } = getState().manageuser;
  try {
    const response = await getListUser(payload);
    return response.data;
  } catch (e) {
    return [];
  }
});

export const getInforUserByIdAsync = createAsyncThunk(
  "manageuser/getInforUserById",
  async (payload: any) => {
    try {
      const response = await getInforUserById(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getInforAsync = createAsyncThunk(
  "manageuser/getInfor",
  async () => {
    try {
      const response = await getInfor();
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getListUserAsync = createAsyncThunk(
  "managestorage/getListUser",
  async (payload) => {
    try {
      const response = await getListUser(payload);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const getRolesUserAsync = createAsyncThunk(
  "managestorage/getRolesUser",
  async (user_id: string) => {
    try {
      const response = await getRolesUser(user_id);
      return response.data;
    } catch (e) {
      return {};
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "managestorage/deleteUser",
  async (id: string) => {
    try {
      const response = await deleteUser(id);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return response;
    } catch (e) {
      return "Delete fail";
    }
  }
);

export const deleteManyUserAsync = createAsyncThunk(
  "managestorage/deleteManyUser",
  async (payload: any) => {
    try {
      const response = await deleteManyUser(payload);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return response;
    } catch (e) {
      return "Delete fail";
    }
  }
);

export const addUserAsync = createAsyncThunk(
  "managestorage/addUser",
  async (payload) => {
    try {
      const response = await addUser(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "managestorage/updateUser",
  async (payload) => {
    try {
      const response = await updateUser(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const getSchemasAsync = createAsyncThunk(
  "managestorage/getSchemas",
  async (payload) => {
    try {
      const response = await getSchemas(payload);

      return response;
    } catch (e) {
      console.log(e);

      return [];
    }
  }
);

export const getSchemasByIdSchemaAsync = createAsyncThunk(
  "managestorage/getSchemasByIdSchema",
  async (payload) => {
    try {
      const response = await getSchemasByIdSchema(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const createRecoveryLinkAsync = createAsyncThunk(
  "managestorage/createRecoveryLink",
  async (payload) => {
    try {
      const response = await createRecoveryLink(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const createRecoveryCodeAsync = createAsyncThunk(
  "managestorage/createRecoveryCode",
  async (payload) => {
    try {
      const response = await createRecoveryCode(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const setAdminAsync = createAsyncThunk(
  "managestorage/setAdmin",
  async (payload) => {
    try {
      const response = await setAdmin(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const setStaffAsync = createAsyncThunk(
  "managestorage/setStaff",
  async (payload) => {
    try {
      const response = await setStaff(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const setUserAsync = createAsyncThunk(
  "managestorage/setUser",
  async (payload) => {
    try {
      const response = await setUser(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const applyAdminRolesAsync = createAsyncThunk(
  "manageuser/applyAdminRoles",
  async (id: string) => {
    try {
      const response = await applyAdminRoles(id);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const applyUserRolesAsync = createAsyncThunk(
  "manageuser/applyUserRoles",
  async (id: string) => {
    try {
      const response = await applyUserRoles(id);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const ManageUserSlice = createSlice({
  name: "manageuser",
  initialState,
  reducers: {
    changeSate: (state, action) => {
      // handle change state

      state.danhSach = action?.payload;
    },
    changeStateSelected: (state, action) => {
      // handle change state

      state.danhSach = action?.payload;
    },

    changeFlagRemoveSelected: (state, action) => {
      state.flagRemoveSelected = action?.payload;
    },

    changeFlagRecoverySelected: (state, action) => {
      state.flagRecoverySelected = action?.payload;
    },

    changeFlagRefresh: (state, action) => {
      state.flagRefresh = action?.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getListUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListUserAsync.fulfilled, (state, action) => {
        state.status = "idle";

        state.danhSach = action.payload?.result ?? [];
      })
      //delete
      .addCase(deleteUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      // getSchemasAsync
      .addCase(getSchemasAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSchemasAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // addUserAsync
      .addCase(addUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      //set admin
      .addCase(setAdminAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setAdminAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      // set staff
      .addCase(setStaffAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setStaffAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      // set user
      .addCase(setUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // get role user
      .addCase(getRolesUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRolesUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      // get my infor

      .addCase(getInforAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInforAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.inforUser = action?.payload;
      })

      .addCase(getIdentitiesAsync.pending, (state, action) => {})
      .addCase(getIdentitiesAsync.fulfilled, (state, action) => {})
      .addCase(getIdentitiesAsync.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

export const {
  changeSate,
  changeStateSelected,
  changeFlagRecoverySelected,
  changeFlagRefresh,
  changeFlagRemoveSelected,
} = ManageUserSlice.actions;

export default ManageUserSlice.reducer;
