import {
  Card,
  CardHeader,
  CardPreview,
  Text,
} from "@fluentui/react-components";
import DatasetIconSvg from "../../assets/dataset.svg";

const TableNamespace = (props: any) => {
  return (
    <>
      {props?.data?.map((item: any, index: number) => (
        <section key={index}>
          <div className="bg-default rounded">
            <Card
              orientation="horizontal"
              className="card-item-storage bg-default"
            >
              <CardPreview className="div-center div-card-preview !flex-row mr-2">
                <img
                  className="img-card-item-catalog"
                  src={DatasetIconSvg}
                  alt="img item"
                />
              </CardPreview>
              <CardHeader
                header={
                  <div>
                    <div>
                      <Text
                        weight="semibold"
                        style={{ overflowWrap: "anywhere" }}
                      >
                        {item?.name}
                      </Text>
                    </div>
                  </div>
                }
              />
            </Card>
          </div>
        </section>
      ))}
    </>
  );
};

export default TableNamespace;
