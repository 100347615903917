import {
  Button,
  Divider,
  Dropdown,
  Option,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  applyAdminRolesAsync,
  applyUserRolesAsync,
  getInforUserByIdAsync,
  getRolesUserAsync,
  getSchemasByIdSchemaAsync,
  setAdminAsync,
  setUserAsync,
  updateUserAsync,
} from "../../store/ManageUserSlice";
import { RootState } from "../../store/store";

import { changeVisibleSpinner } from "../../store/NavSlice";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import EditTraitsComp from "./EditTraits";

const FormEditComp = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const { t } = useTranslation();

  const [values, setValues] = useState<any>();
  const [identity, setIdentity] = useState<any>(null);
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { dispatchToast } = useToastController(toasterId);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  const [schema, setSchema] = useState<any>(undefined);
  const [flag, setFlag] = useState(false);

  const [group, setGroup] = useState("");
  const [intinalGroup, setIntinalGroup] = useState("");

  useEffect(() => {
    (async function () {
      if (id)
        try {
          dispatch(changeVisibleSpinner(true));
          const res = await dispatch(
            // @ts-ignore
            getInforUserByIdAsync({ id: id })
          ).unwrap();

          setIdentity(res?.data ?? {});

          const resSchema = await dispatch(
            // @ts-ignore
            getSchemasByIdSchemaAsync({ id: res?.data?.schema_id })
          ).unwrap();

          const resRolesUser = await dispatch(
            // @ts-ignore
            getRolesUserAsync(id)
          ).unwrap();

          setGroup(
            resRolesUser?.length === 0 ||
              resRolesUser?.includes?.("User") ||
              resRolesUser?.includes?.("user")
              ? "User"
              : resRolesUser?.includes?.("Admin") ||
                resRolesUser?.includes?.("admin")
              ? "Admin"
              : resRolesUser?.includes?.("Staff") ||
                resRolesUser?.includes?.("staff")
              ? "Staff"
              : "User"
          );

          setIntinalGroup(
            resRolesUser?.length === 0 ||
              resRolesUser?.includes?.("User") ||
              resRolesUser?.includes?.("user")
              ? "User"
              : resRolesUser?.includes?.("Admin") ||
                resRolesUser?.includes?.("admin")
              ? "Admin"
              : resRolesUser?.includes?.("Staff") ||
                resRolesUser?.includes?.("staff")
              ? "Staff"
              : "User"
          );

          setSchema(resSchema?.data ?? {});

          dispatch(changeVisibleSpinner(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [id]);

  const notify = (msg: any, type: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>Notification</ToastTitle>
        <ToastBody>{msg}</ToastBody>
      </Toast>,
      { timeout: 5000, intent: type }
    );
  };

  const handleUpdate = async () => {
    try {
      dispatch(changeVisibleSpinner(true));

      const newPay = {
        traits: values?.traits,
        schema_id: identity?.schema_id,
        metadata_admin: values?.adminMetadata,
        metadata_public: values?.publicMetadata,
        id: identity?.id,
        state: values?.state,
      };

      if (intinalGroup !== group) {
        if (group === "User") {
          const resSetUser = await dispatch(
            // @ts-ignore
            setUserAsync(id)
          ).unwrap();

          const resApplyRolesUser = await dispatch(
            // @ts-ignore
            applyUserRolesAsync(id)
          ).unwrap();

          if (resSetUser?.status === 200 && resApplyRolesUser?.status === 200) {
            setTimeout(() => {
              notify("Set group user successfully", "success");
              // navigate(`/manage-user`);
              // dispatch(changeVisibleSpinner(false));
            }, 2000);
          } else {
            setTimeout(() => {
              notify("Set group user fail", "error");
              // navigate(`/manage-user`);
              // dispatch(changeVisibleSpinner(false));
            }, 2000);
          }
        }

        if (group === "Admin") {
          const resSetAdmin = await dispatch(
            // @ts-ignore
            setAdminAsync(id)
          ).unwrap();

          const resApplyRolesAdmin = await dispatch(
            // @ts-ignore
            applyAdminRolesAsync(id)
          ).unwrap();

          if (
            resSetAdmin?.status === 200 &&
            resApplyRolesAdmin?.status === 200
          ) {
            setTimeout(() => {
              notify("Set group admin successfully", "success");
              // navigate(`/manage-user`);
              // dispatch(changeVisibleSpinner(false));
            }, 2000);
          } else {
            setTimeout(() => {
              notify("Set group admin fail", "error");
              // navigate(`/manage-user`);
              // dispatch(changeVisibleSpinner(false));
            }, 2000);
          }
        }
      }

      const res = await dispatch(
        // @ts-ignore
        updateUserAsync({ ...newPay })
      ).unwrap();

      if (res?.status === 201 || res?.status === 200) {
        notify("Update identities successfully", "success");
        setTimeout(() => {
          navigate(`/identities/${identity?.id}`, {
            state: { itemEdit: res?.data },
          });
          // @ts-ignore
          dispatch(changeVisibleSpinner(false));
        }, 1000);
      } else {
        notify("Update identities fail", "error");
        dispatch(changeVisibleSpinner(false));
      }
    } catch (e) {
      console.error(e);
      dispatch(changeVisibleSpinner(false));
    }
  };
  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}
      <div className="div-form-storage">
        {identity && (
          <>
            <div className="flex w-full justify-between">
              <div style={{ width: "95%", marginRight: "35px" }}>
                <p className="title-div-form">Schema</p>
                <Dropdown
                  placeholder="Select schema"
                  className="div-dropdown"
                  selectedOptions={[identity?.schema_id]}
                  value={identity?.schema_id}
                  disabled
                >
                  <Option>{identity?.schema_id}</Option>
                </Dropdown>

                <pre className="schemaPre mt-3">
                  {JSON.stringify(schema, null, 2)}
                </pre>
              </div>
              <Divider vertical />
              <div style={{ width: "95%", marginLeft: "35px" }}>
                <EditTraitsComp
                  modi="edit"
                  identity={identity}
                  values={values}
                  setValues={(val: any) => setValues(val)}
                />
              </div>
            </div>
          </>
        )}

        <div className="flex absolute bottom-4 right-4 gap-x-2">
          <Button
            appearance="outline"
            className="h-8 !bg-ink-400 border border-white/0.03"
            onClick={() => navigate(`/identities`)}
            disabled={visibleSpinner}
          >
            {t("Cancel")}
          </Button>

          <Button
            appearance="outline"
            className="h-8 !bg-primary-500 border border-white/0.03 !text-black"
            onClick={() => handleUpdate()}
            disabled={visibleSpinner}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormEditComp;
