import { useEffect } from "react";
import { RootState } from "../../store/store";
import { resetState } from "../../store/pagingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
export default function RelationshipPage() {
  const { myRoles } = useSelector((state: RootState) => state.role);
  const { namespaces } = useSelector(
    (state: RootState) => state.managenamespace
  );
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    // checkRoles(myRoles);
    return () => {
      dispatch(resetState());
    };
  }, []);

  const checkRoles = (roles: Array<any>) => {
    // check permission to access route
    if (
      !roles ||
      roles.length == 0 ||
      (roles.length && roles[roles.length - 1] != "admin")
    )
      navigate("/403");
    else {
      if (location.pathname === "/relationship") {
        navigate(
          namespaces.length > 0 ? "/relationship/" + namespaces[0].name : "/404"
        );
      }
    }
  };

  return <Outlet></Outlet>;
}
