import React, { useEffect, useState } from "react";
import {
  FieldKind,
  SchemaField,
  SchemaService,
} from "../../services/schema-service";
import { Checkbox, Field, Select, Switch } from "@fluentui/react-components";
import { RenderTraitField } from "./render-field";

export function mapFieldKindToValueKey(fieldKind: FieldKind) {
  switch (fieldKind) {
    case "trait":
      return "traits";
    case "metadata_public":
      return "publicMetadata";
    case "metadata_admin":
      return "adminMetadata";
  }
}

export interface ValueObject {
  state: any;
  traits: any;
  publicMetadata: any;
  adminMetadata: any;
}

async function fillTraits(identity: any, schemaFields: any[]): Promise<any> {
  const map = await SchemaService.getTableDetailListModelFromKratosIdentity(
    identity
  );
  const traits: any = {};

  for (const [key, value] of Object.entries(map.traits)) {
    if (key !== "key") {
      schemaFields.forEach((f) => {
        if (f.name === key && f.fieldKind === "trait") {
          if (f.parentName) {
            if (!traits[f.parentName]) {
              traits[f.parentName] = {};
            }
            traits[f.parentName][f.name] = value;
          } else {
            traits[f.name] = value;
          }
        }
      });
    }
  }
  return traits;
}

async function fillMetadata(
  identity: any,
  schemaFields: any[],
  metadataKind: "metadata_public" | "metadata_admin"
): Promise<any> {
  const map = await SchemaService.getTableDetailListModelFromKratosIdentity(
    identity
  );
  const metadata: any = {};

  for (const [key, value] of Object.entries(map[metadataKind])) {
    if (key !== "key") {
      schemaFields.forEach((f) => {
        if (f.name === key && f.fieldKind === metadataKind) {
          if (f.parentName) {
            if (!metadata[f.parentName]) {
              metadata[f.parentName] = {};
            }
            metadata[f.parentName][f.name] = value;
          } else {
            metadata[f.name] = value;
          }
        }
      });
    }
  }
  return metadata;
}

const EditTraitsComp = (props: any) => {
  const [identity] = useState<any>(props?.identity);
  const [schemaFields, setSchemaFields] = useState<any[]>();
  const [errorText, setErrorText] = useState<string>();

  useEffect(() => {
    async function prepare() {
      let newSchemaFields: any[] = [];
      let valueObject: any;

      if (identity && props.modi === "edit") {
        newSchemaFields = await SchemaService.getSchemaFieldsFromIdentity(
          identity
        );
        valueObject = {
          state: identity.state!,
          traits: await fillTraits(identity, newSchemaFields),
          publicMetadata: await fillMetadata(
            identity,
            newSchemaFields,
            "metadata_public"
          ),
          adminMetadata: await fillMetadata(
            identity,
            newSchemaFields,
            "metadata_admin"
          ),
        };
      } else if (props.schema && props.modi === "new") {
        newSchemaFields = SchemaService.getSchemaFields(props.schema);
        valueObject = {
          state: "active",
          traits: {},
          adminMetadata: {},
          publicMetadata: {},
        };
      } else {
        throw new Error(
          "Either identity (modi=edit) or schema (modi=new) has to be definied"
        );
      }

      // array value compare
      if (JSON.stringify(newSchemaFields) !== JSON.stringify(schemaFields)) {
        setSchemaFields(newSchemaFields);
      }

      // array value compare
      if (JSON.stringify(valueObject) !== JSON.stringify(props?.values)) {
        props?.setValues(valueObject);
      }
    }

    prepare();
  }, []);

  const traits =
    schemaFields &&
    schemaFields.filter((elem, _key) => elem.fieldKind === "trait");
  const publicMetadata =
    schemaFields &&
    schemaFields.filter((elem, _key) => elem.fieldKind === "metadata_public");
  const adminMetadata =
    schemaFields &&
    schemaFields.filter((elem, _key) => elem.fieldKind === "metadata_admin");

  return (
    <div>
      <p className="title-div-form">Standard Properties</p>

      {props?.values && (
        // <Checkbox
        //   label="Identity State (active, inactive)"
        //   defaultChecked={props?.values?.state === "active"}
        //   onChange={(ev: any, data: any) => {
        //     const newValues = props?.values;
        //     newValues.state = data.checked ? "active" : "inactive";
        //     props?.setValues(newValues);
        //   }}
        // ></Checkbox>

        <Switch
          label="Identity State (active, inactive)"
          defaultChecked={props?.values?.state === "active"}
          onChange={(ev: any, data: any) => {
            const newValues = props?.values;
            newValues.state = data.checked ? "active" : "inactive";
            props?.setValues(newValues);
          }}
        />
      )}

      <p className="title-div-form">Custom Traits</p>

      {props?.values && traits?.length ? (
        traits?.map((elem: any, key: any) => {
          return (
            <div key={key}>
              <RenderTraitField
                schemaField={elem}
                fieldValues={props?.values}
                setValues={(values: any) => {
                  props?.setValues(values);
                }}
              ></RenderTraitField>
            </div>
          );
        })
      ) : (
        <p>None</p>
      )}

      {publicMetadata?.length ? (
        <div>
          <p className="title-div-form">Public Metadata</p>
          {props?.values &&
            publicMetadata?.map((elem: any, key: any) => {
              return (
                <div key={key}>
                  <RenderTraitField
                    schemaField={elem}
                    fieldValues={props?.values}
                    setValues={(values: any) => {
                      props?.setValues(values);
                    }}
                  ></RenderTraitField>
                </div>
              );
            })}
        </div>
      ) : null}

      {adminMetadata?.length ? (
        <div>
          <p className="title-div-form">Admin Metadata</p>
          {props?.values &&
            adminMetadata.map((elem: any, key: any) => {
              return (
                <div key={key}>
                  <RenderTraitField
                    schemaField={elem}
                    fieldValues={props?.values}
                    setValues={(values: any) => {
                      props?.setValues(values);
                    }}
                  ></RenderTraitField>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
};

export default EditTraitsComp;
