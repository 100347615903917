export const horizontal = 20;
export const vertical = 65;

export const timeout = 1000;

export const HASH_ROLE_ADMIN = "d033e22ae348aeb5660fc2140aec35850c4da997";
export const HASH_ROLE_USE = "12dea96fec20593566ab75692c9949596833adc9";
export const HASH_ROLE_STAFF = "6ccb4b7c39a6e77f76ecfa935a855c6c46ad5611";

export const MD5_TEXT_ROLE = "29a7e96467b69a9f5a93332e29e9b0de";
export const MD5_TEXT_ROLE_LOGIN = "751bf0ef215d2c978e4a2db23fb50262";
