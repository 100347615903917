import axios from 'axios';

import { loadConfig } from '../config';

const api = axios.create();

api.defaults.timeout = 60000000;
api.defaults.withCredentials = true;

api.interceptors.response.use(
  (response: any) =>
    // Do something with response data
    response,
  async (error: any) => {
    console.log(error, "error");
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error?.response?.status === 401) {
      

      console.log(error, "401");
      window.location.replace(localStorage.getItem("ipAccount") ?? '');
    }

    if (error?.response?.status === 403) {
      alert(
        error?.response?.data?.error?.message ??
          "You do not have permission to access this resource."
      );
    }

    return Promise.reject(error);
  }
);

export default api;
