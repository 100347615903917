import React, { useEffect } from "react";
import FormAddComp from "../../components/Identities/FormAdd";
import { changeName, changePath } from "../../store/pageSlice";
import { useDispatch } from "react-redux";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import FormChangePwdCmp from "../../components/Identities/FormChangePwd";
import { useLocation } from "react-router-dom";



const ChangePwdPage = () => {
  const dispatch = useDispatch();
    const location = useLocation();

   const dataBread = [
     {
       key: 1,
       value: "Identities",
       path: "identities",
     },
     {
       key: 2,
       value: location?.state?.itemEdit?.traits?.name?.first
         ? `Change password ${location?.state?.itemEdit?.traits?.name?.first} ${location?.state?.itemEdit?.traits?.name?.last}`
         : `Change password ${location?.state?.itemEdit?.traits?.email}`,
     },
   ];

  useEffect(() => {
    dispatch(changePath("/identities/change-pwd"));
    dispatch(changeName("identities/change-pwd"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);
  return <FormChangePwdCmp />;
};

export default ChangePwdPage;
