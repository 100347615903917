import { useEffect } from "react";
import { RootState } from "../../store/store";
import { resetState } from "../../store/pagingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
export default function IdentitiesPage() {
  const { myRoles } = useSelector((state: RootState) => state.role);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // checkRoles(myRoles);
    return () => {
      dispatch(resetState());
    };
  }, []);

  const checkRoles = (roles: Array<any>) => {
    // check permission to access route
    if (
      !roles ||
      roles.length == 0 ||
      (roles.length && roles[roles.length - 1] != "admin")
    )
      navigate("/403");
  };
  return <Outlet></Outlet>;
}
