import {
  Dropdown,
  Input,
  Option,
  Radio,
  RadioGroup,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import { arrRelation } from "../../utils/data";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  changeObject,
  changeRelation,
  changeSubjectId,
  changeSubjectNamespace,
  changeSubjectObject,
  changeSubjectRelation,
} from "../../store/ManageRelationship";
import { getListAsync } from "../../store/ManageNamespace";

const FilterRelationshipComp = () => {
  const dispatch = useDispatch();
  const {
    subject_id,
    subject_namespace,
    subject_object,
    subject_relation,
    object,
    relation,
  } = useSelector((state: RootState) => state.managerelationship);
  const [arrObject, setArrObject] = useState<any>([]);
  const [flagAddSubjectSet, setFlagAddSubjectSet] = useState<boolean>(false);
  const [arrNamespaces, setArrNamespaces] = useState<any>([]);

  useEffect(() => {
    (async function () {
      try {
        const a: any = localStorage.getItem("arrObject");
        setArrObject(a.split(",") ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const res = await dispatch(
          // @ts-ignore
          getListAsync()
        ).unwrap();
        if (res && Object.keys(res).length) {
          setArrNamespaces(res?.namespaces ?? []);
        }
      } catch (e) {}
    })();
  }, []);

  return (
    <div>
      <p className="mb-2 mt-2">Object</p>
      <div className="autocomplete-wrapper div-dropdown">
        <Autocomplete
          inputProps={{
            placeholder: "Select object",
            multiple: true,
          }}
          value={object}
          items={arrObject}
          getItemValue={(item) => item}
          shouldItemRender={(item: any, val: any) => {
            return item.toLowerCase().indexOf(val.toLowerCase()) !== -1;
          }}
          renderMenu={(item) => <div className="dropdown">{item}</div>}
          renderItem={(item, isHighlighted) => (
            <div className={`item ${isHighlighted ? "selected-item" : ""}`}>
              {item}
            </div>
          )}
          onChange={(event: any, val: any) => dispatch(changeObject(val))}
          onSelect={(val: any) => dispatch(changeObject(val))}
        />
      </div>
      <p className="mb-2 mt-4">Relation</p>
      <div className="autocomplete-wrapper div-dropdown">
        <Autocomplete
          inputProps={{
            placeholder: "Select relation",
          }}
          value={relation}
          items={arrRelation}
          getItemValue={(item) => item}
          shouldItemRender={(item: any, val: any) => {
            return item.toLowerCase().indexOf(val.toLowerCase()) !== -1;
          }}
          renderMenu={(item) => <div className="dropdown">{item}</div>}
          renderItem={(item, isHighlighted) => (
            <div className={`item ${isHighlighted ? "selected-item" : ""}`}>
              {item}
            </div>
          )}
          onChange={(event: any, val: any) => dispatch(changeRelation(val))}
          onSelect={(val: any) => dispatch(changeRelation(val))}
        />
      </div>

      <RadioGroup
        value={flagAddSubjectSet ? "subject_set" : "subject_id"}
        onChange={(_, data) => {
          setFlagAddSubjectSet(data?.value === "subject_set" ? true : false);
          if (data?.value === "subject_set") {
            dispatch(changeSubjectId(null));
          } else {
            dispatch(changeSubjectNamespace(null));
            dispatch(changeSubjectObject(undefined));
            dispatch(changeRelation(undefined));
          }
        }}
        layout="horizontal"
        className="mb-2 mt-4"
      >
        <Radio value="subject_id" label="Subject id" />
        <Radio value="subject_set" label="Subject set" />
      </RadioGroup>

      {flagAddSubjectSet ? (
        <>
          <p className="mb-2">
            Namespaces <span style={{ color: "red" }}>*</span>
          </p>
          <Dropdown
            placeholder="Select schema"
            className="div-dropdown"
            value={subject_namespace}
            onOptionSelect={(e: any, data: any) => {
              dispatch(changeSubjectNamespace(data?.optionValue));
            }}
          >
            {arrNamespaces?.map((item: any) => (
              <Option value={item?.name} key={item?.name}>
                {item?.name}
              </Option>
            ))}
          </Dropdown>

          <p className="mb-2 mt-6">
            Object <span style={{ color: "red" }}>*</span>
          </p>
          <div className="autocomplete-wrapper div-dropdown">
            <Autocomplete
              inputProps={{
                placeholder: "Select object",
              }}
              value={subject_object}
              items={arrObject}
              getItemValue={(item) => item}
              shouldItemRender={(item: any, val: any) => {
                return item.toLowerCase().indexOf(val.toLowerCase()) !== -1;
              }}
              renderMenu={(item) => <div className="dropdown">{item}</div>}
              renderItem={(item, isHighlighted) => (
                <div className={`item ${isHighlighted ? "selected-item" : ""}`}>
                  {item}
                </div>
              )}
              onChange={(event: any, val: any) =>
                dispatch(changeSubjectObject(val))
              }
              onSelect={(val: any) => dispatch(changeSubjectObject(val))}
            />
          </div>

          <p className="mb-2 mt-6">
            Relation <span style={{ color: "red" }}>*</span>
          </p>
          <div className="autocomplete-wrapper div-dropdown">
            <Autocomplete
              inputProps={{
                placeholder: "Select relation",
              }}
              value={subject_relation}
              items={arrRelation}
              getItemValue={(item) => item}
              shouldItemRender={(item: any, val: any) => {
                return item.toLowerCase().indexOf(val.toLowerCase()) !== -1;
              }}
              renderMenu={(item) => <div className="dropdown">{item}</div>}
              renderItem={(item, isHighlighted) => (
                <div className={`item ${isHighlighted ? "selected-item" : ""}`}>
                  {item}
                </div>
              )}
              onChange={(event: any, val: any) =>
                dispatch(changeSubjectRelation(val))
              }
              onSelect={(val: any) => dispatch(changeSubjectRelation(val))}
            />
          </div>
        </>
      ) : (
        <>
          <p className="mb-2">
            Subject Id <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            placeholder="Enter subject id"
            className="div-dropdown"
            value={subject_id}
            onChange={(ev: any, data: any) => {
              dispatch(changeSubjectId(data?.value));
            }}
          />
        </>
      )}
    </div>
  );
};

export default FilterRelationshipComp;
