import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

import Pagination from "../Pagination/index";
export default function Footer() {
  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );
  return (
    <div
      className={
        "w-full h-full px-6 py-3 text-center text-white " +
        (pageName == "mainMap"
          ? "border-t border-s-background1 bg-s-background1"
          : "border-t border-s-line1 bg-s-background2")
      }
    >
      <Pagination />
    </div>
  );
}
