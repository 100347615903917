/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import {
  Button,
  Divider,
  Dropdown,
  Input,
  Option,
  Radio,
  RadioGroup,
  Toast,
  ToastBody,
  ToastTitle,
  makeStyles,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store/store";
import { arrObjectEomAcp, arrRelation } from "../../utils/data";
import LoadingGlobalComp from "../Loading/LoadingGlobal";

import Autocomplete from "react-autocomplete";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  addMultipleRelationshipAsync,
  addRelationshipAsync,
  getListAsync,
} from "../../store/ManageRelationship";
import { changeVisibleSpinner } from "../../store/NavSlice";

const useStyles = makeStyles({
  // these styles wrap the value text within the dropdown button and cause it to truncate
  truncatedText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const FormAddComp = (props: any) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [object, setObject] = useState<any>(
    props?.type === "insert" ? [] : undefined
  );
  const [relation, setRelation] = useState<any>(undefined);
  const [subjectId, setSubjectId] = useState<any>();

  const [objectSubjectSet, setObjectSubjectSet] = useState<any>(undefined);
  const [relationSubjectSet, setRelationSubjectSet] = useState<any>(undefined);

  const [flagAddSubjectSet, setFlagAddSubjectSet] = useState<boolean>(false);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const [arrObject, setArrObject] = useState<any>([]);

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { dispatchToast } = useToastController(toasterId);

  const notify = (msg: any, type: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>Notification</ToastTitle>
        <ToastBody>{msg}</ToastBody>
      </Toast>,
      { timeout: 5000, intent: type }
    );
  };

  const handleAdd = async () => {
    if (props?.type === "add") {
      try {
        dispatch(changeVisibleSpinner(true));

        let payload = flagAddSubjectSet
          ? {
              namespace: id,
              object: object,
              relation: relation,
              subject_set: {
                namespace: id,
                object: objectSubjectSet,
                relation: relationSubjectSet,
              },
            }
          : {
              namespace: id,
              object: object,
              relation: relation,
              subject_id: subjectId,
            };

        let a = await checkDuplicateRelations(payload);

        if (!a) {
          const res = await dispatch(
            // @ts-ignore
            addRelationshipAsync(payload)
          ).unwrap();

          if (res?.status === 201 || res?.status === 200) {
            if (!arrObject?.includes(object)) {
              arrObject?.unshift(object);
              localStorage.setItem("arrObject", arrObject);
            }
            notify("Add relationship successfully", "success");
            setTimeout(() => {
              // navigate(`/relationship/${id}`);
              // @ts-ignore
              dispatch(changeVisibleSpinner(false));
            }, 1000);
          } else {
            notify("Add relationship failed", "error");
            setTimeout(() => {
              dispatch(changeVisibleSpinner(false));
            }, 1000);
          }
        } else {
          notify("Duplicate relationship", "error");
          setTimeout(() => {
            dispatch(changeVisibleSpinner(false));
          }, 1000);
        }
      } catch (e) {
        console.error(e);
        notify("Error", "error");
        setTimeout(() => {
          dispatch(changeVisibleSpinner(false));
        }, 1000);
      }
    } else {
      dispatch(changeVisibleSpinner(true));
      let objectCopy = object?.filter((item: any) => item !== "all");

      let newPay: any = [];

      for (let i = 0; i < objectCopy?.length; i++) {
        let a = await checkDuplicateRelations(
          flagAddSubjectSet
            ? {
                namespace: id,
                object: objectCopy?.[i],
                relation: relation,
                subject_set: {
                  namespace: id,
                  object: objectSubjectSet,
                  relation: relationSubjectSet,
                },
              }
            : {
                namespace: id,
                object: objectCopy?.[i],
                relation: relation,
                subject_id: subjectId,
              }
        );

        if (!a) {
          newPay.push({
            action: "insert",
            relation_tuple: flagAddSubjectSet
              ? {
                  namespace: id,
                  object: objectCopy?.[i],
                  relation: relation,
                  subject_set: {
                    namespace: id,
                    object: objectSubjectSet,
                    relation: relationSubjectSet,
                  },
                }
              : {
                  namespace: id,
                  object: objectCopy?.[i],
                  relation: relation,
                  subject_id: subjectId,
                },
          });
        }
      }

      if (newPay?.length > 0 && objectCopy?.length > 0) {
        try {
          const res = await dispatch(
            // @ts-ignore
            addMultipleRelationshipAsync(newPay)
          ).unwrap();
          if (res?.status === 204) {
            notify("Insert relationships successfully", "success");
            setTimeout(() => {
              navigate(`/relationship/${id}`);
              // @ts-ignore
              dispatch(changeVisibleSpinner(false));
            }, 1000);
          } else {
            notify("Insert relationships failed", "error");
            setTimeout(() => {
              dispatch(changeVisibleSpinner(false));
            }, 1000);
          }
        } catch (e) {
          console.error(e);
          notify("Error", "error");
          setTimeout(() => {
            dispatch(changeVisibleSpinner(false));
          }, 1000);
        }
      } else {
        notify("Duplicate all relationships", "error");
        setTimeout(() => {
          dispatch(changeVisibleSpinner(false));
        }, 1000);
      }
    }
  };

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: 1,
          value: `Relationship ${id}`,
          path: "./relationship/" + id,
        },
        {
          key: 2,
          value: `${props?.type}`,
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, [id]);

  useEffect(() => {
    (async function () {
      try {
        const a: any = localStorage.getItem("arrObject");
        setArrObject(a.split(",") ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const checkBtnSave = () => {
    let a = false;
    if (visibleSpinner) {
      a = true;
    }
    if (flagAddSubjectSet && (!objectSubjectSet || !relationSubjectSet)) {
      a = true;
    }
    if (!flagAddSubjectSet && !subjectId) {
      a = true;
    }
    return a;
  };

  const checkDuplicateRelations = async (payload: any) => {
    let newPay: any = {};

    if (payload?.subject_set?.object) {
      newPay = {
        namespace: id,
        object: payload?.object,
        relation: payload?.relation,
        "subject_set.namespace": payload?.subject_set?.namespace,
        "subject_set.object": payload?.subject_set?.object,
        " subject_set.relation": payload?.subject_set?.relation,
      };
    } else {
      newPay = payload;
    }
    let a = false;
    //@ts-ignore
    const res = await dispatch(getListAsync(newPay)).unwrap();
    if (res?.relation_tuples?.length > 0) {
      a = true;
    }

    return a;
  };

  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}

      <div className="div-form-storage">
        <div className="flex w-full justify-between">
          <div style={{ width: "95%", marginRight: "35px" }}>
            <p className="mb-2 mt-[37px]">Namespaces</p>
            <Dropdown
              placeholder="Select schema"
              className="div-dropdown"
              value={id}
              disabled
            >
              {[]?.map((item: any) => (
                <Option value={item} key={item?.id}>
                  {item?.id}
                </Option>
              ))}
            </Dropdown>

            <p className="mb-2 mt-6">Object</p>
            {props?.type === "insert" && (
              <Dropdown
                placeholder="Select object"
                className="div-dropdown"
                onOptionSelect={(e: any, data: any) => {
                  if (data?.selectedOptions?.includes("all")) {
                    let a = arrObjectEomAcp?.concat("all");

                    setObject(a);
                  } else {
                    let b = object;

                    if (b?.includes("all")) {
                      setObject([]);
                    } else {
                      setObject(data?.selectedOptions);
                    }
                  }
                }}
                selectedOptions={object}
                value={object?.length > 0 ? object?.join(", ") : undefined}
                multiselect
                button={
                  <span className={styles.truncatedText}>
                    {object?.length > 0 ? object?.join(", ") : "Select object"}
                  </span>
                }
              >
                <Option value="all" key="all">
                  Select all
                </Option>
                {arrObjectEomAcp?.map((item: any) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Dropdown>
            )}
            {props?.type === "add" && (
              <div className="autocomplete-wrapper div-dropdown">
                <Autocomplete
                  inputProps={{
                    placeholder: "Select object",
                    multiple: true,
                  }}
                  value={object}
                  items={arrObject}
                  getItemValue={(item) => item}
                  shouldItemRender={(item: any, val: any) => {
                    return item.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                  }}
                  renderMenu={(item) => <div className="dropdown">{item}</div>}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`item ${isHighlighted ? "selected-item" : ""}`}
                    >
                      {item}
                    </div>
                  )}
                  onChange={(event: any, val: any) => setObject(val)}
                  onSelect={(val: any) => setObject(val)}
                />
              </div>
            )}

            <p className="mb-2 mt-6">Relation</p>
            <div className="autocomplete-wrapper div-dropdown">
              <Autocomplete
                inputProps={{
                  placeholder: "Select relation",
                }}
                value={relation}
                items={arrRelation}
                getItemValue={(item) => item}
                shouldItemRender={(item: any, val: any) => {
                  return item.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                }}
                renderMenu={(item) => <div className="dropdown">{item}</div>}
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${isHighlighted ? "selected-item" : ""}`}
                  >
                    {item}
                  </div>
                )}
                onChange={(event: any, val: any) => setRelation(val)}
                onSelect={(val: any) => setRelation(val)}
              />
            </div>

            {/* <Dropdown
              placeholder="Select subject id"
              className="div-dropdown"
              onOptionSelect={(e: any, data: any) => {
                setSubjectId(data?.optionValue);
                setSubjectName(data?.optionText);
              }}
              selectedOptions={[subjectId]}
              value={subjectName}
            >
              {identities?.map((item: any) => (
                <Option
                  value={item?.id}
                  key={item?.id}
                  text={
                    item?.traits?.name?.first + " " + item?.traits?.name?.last
                  }
                >
                  {item?.traits?.name?.first}&nbsp;{item?.traits?.name?.last}
                </Option>
              ))}
            </Dropdown> */}
          </div>
          <Divider vertical />
          <div style={{ width: "95%", marginLeft: "35px" }}>
            <RadioGroup
              value={flagAddSubjectSet ? "subject_set" : "subject_id"}
              onChange={(_, data) => {
                setFlagAddSubjectSet(
                  data?.value === "subject_set" ? true : false
                );
              }}
              layout="horizontal"
              className="mb-1"
            >
              <Radio value="subject_id" label="Subject id" />
              <Radio value="subject_set" label="Subject set" />
            </RadioGroup>
            {flagAddSubjectSet ? (
              <>
                <p className="mb-2">
                  Namespaces <span style={{ color: "red" }}>*</span>
                </p>
                <Dropdown
                  placeholder="Select schema"
                  className="div-dropdown"
                  value={id}
                  disabled
                >
                  {[]?.map((item: any) => (
                    <Option value={item} key={item?.id}>
                      {item?.id}
                    </Option>
                  ))}
                </Dropdown>

                <p className="mb-2 mt-6">
                  Object <span style={{ color: "red" }}>*</span>
                </p>
                <div className="autocomplete-wrapper div-dropdown">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Select object",
                    }}
                    value={objectSubjectSet}
                    items={arrObject}
                    getItemValue={(item) => item}
                    shouldItemRender={(item: any, val: any) => {
                      return (
                        item.toLowerCase().indexOf(val.toLowerCase()) !== -1
                      );
                    }}
                    renderMenu={(item) => (
                      <div className="dropdown">{item}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    onChange={(event: any, val: any) =>
                      setObjectSubjectSet(val)
                    }
                    onSelect={(val: any) => setObjectSubjectSet(val)}
                  />
                </div>

                <p className="mb-2 mt-6">
                  Relation <span style={{ color: "red" }}>*</span>
                </p>
                <div className="autocomplete-wrapper div-dropdown">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Select relation",
                    }}
                    value={relationSubjectSet}
                    items={arrRelation}
                    getItemValue={(item) => item}
                    shouldItemRender={(item: any, val: any) => {
                      return (
                        item.toLowerCase().indexOf(val.toLowerCase()) !== -1
                      );
                    }}
                    renderMenu={(item) => (
                      <div className="dropdown">{item}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    onChange={(event: any, val: any) =>
                      setRelationSubjectSet(val)
                    }
                    onSelect={(val: any) => setRelationSubjectSet(val)}
                  />
                </div>
              </>
            ) : (
              <>
                <p className="mb-2">
                  Subject Id <span style={{ color: "red" }}>*</span>
                </p>
                <Input
                  placeholder="Enter subject id"
                  className="div-dropdown"
                  value={subjectId}
                  onChange={(ev: any, data: any) => {
                    setSubjectId(data?.value);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex absolute bottom-4 right-4 gap-x-2">
        <Button
          appearance="outline"
          className="h-8 !bg-ink-400 border border-white/0.03"
          onClick={() => navigate(`/relationship/${id}`)}
          disabled={visibleSpinner}
        >
          {t("Cancel")}
        </Button>

        <Button
          appearance="outline"
          className="h-8 !bg-primary-500 border border-white/0.03 !text-black"
          disabled={checkBtnSave()}
          onClick={() => handleAdd()}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default FormAddComp;
