export const arrPathVisibleSeachBar = ["relationship/:id"];

export const arrPathFliter = ["relationship/:id"];

export const arrPathPaing = ["identities", "namespaces", "schemas"];
export const arrRelation = ["access", "is", "owner", "view"];
export const arrObjectEomAcp = [
  "acp::eom::whoami::access",
  "acp::eom::stac::collections::filter",
  "acp::eom::stac::collections::get",
  "acp::eom::stac::items::filter",
  "acp::eom::stac::items::get",
  "acp::eom::stac::search",
  "acp::eom::stac::collections::add",
  "acp::eom::stac::collections::update",
  "acp::eom::stac::collections::delete",
  "acp::eom::stac::collections::check",
  "acp::eom::stac::items::update",
  "acp::eom::stac::items::delete",
  "acp::eom::stac::items::check",
  "acp::eom::storage::filter",
  "acp::eom::storage::get",
  "acp::eom::storage::add",
  "acp::eom::storage::update",
  "acp::eom::storage::delete",
  "acp::eom::order::me::filter",
  "acp::eom::order::me::get",
  "acp::eom::order::me::item::filter",
  "acp::eom::order::filter",
  "acp::eom::order::get",
  "acp::eom::order::add",
  "acp::eom::order::item::filter",
  "acp::eom::order::item::add",
  "acp::eom::order::item::delete",
  "acp::eom::order::state::filter",
  "acp::eom::order::state::submit",
  "acp::eom::order::state::cancel",
  "acp::eom::order::state::accept",
  "acp::eom::order::state::reject",
  "acp::eom::quota::filter",
  "acp::eom::quota::get",
  "acp::eom::quota::add",
  "acp::eom::quota::update",
  "acp::eom::quota::delete",
  "acp::eom::quota::expire::filter",
  "acp::eom::quota::expire::add",
  "acp::eom::quota::expire::delete",
  "acp::eom::user::quota::get",
  "acp::eom::user::quota::asign",
  "acp::eom::user::quota::remove",
  "acp::eom::history::scan::filter",
  "acp::eom::history::scan::get",
  "acp::eom::scan::start_scan",
  "acp::eom::user::whoami",
  "acp::eom::user::get_my_roles",
  "acp::eom::user::get_roles",
  "acp::eom::user::set_staff",
  "acp::eom::user::set_admin",
];
