import {
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
  Tooltip,
} from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DeleteSvg from "../../assets/delete.svg";
import RecoverySvg from "../../assets/recovery.svg";
import ReloadSvg from "../../assets/reload.svg";
import InsertSvg from "../../assets/insert.svg";
import { RootState } from "../../store/store";
import AddSvg from "./../../assets/add.svg";
import {
  changeFlagRecoverySelected,
  changeFlagRefresh,
  changeFlagRemoveSelected,
} from "../../store/ManageUserSlice";

import {
  changeFlagRefresh as changeFlagRefreshRelationship,
  changeFlagRemove as changeFlagRemoveRelationship,
} from "../../store/ManageRelationship";

const ActionToolNavComp = (props: any) => {
  const {
    visibleBtnCreate,
    pathCreate,
    stateItem,
    visibleSpinner,
    visibleToolAction,
  } = useSelector((state: RootState) => state.nav);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  console.log(window.location.pathname);

  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );

  const handleRefresh = () => {
    if (pageName === "identities") {
      dispatch(changeFlagRefresh(true));
    } else if (pageName === "relationship/:id") {
      dispatch(changeFlagRefreshRelationship(true));
    }
  };

  const renderButtonCreate = () => {
    return visibleBtnCreate ? (
      <>
        <div
          className="mr-3 !cursor-pointer"
          onClick={() =>
            navigate(
              `${pathCreate}`,
              stateItem
                ? {
                    state: stateItem,
                  }
                : undefined
            )
          }
        >
          <img src={AddSvg} className="img-svg-icon-add" alt="Map" />
        </div>
        {pageName === "relationship/:id" && (
          <>
            <Tooltip
              relationship="description"
              content={"Insert many relationships"}
              positioning="after"
            >
              <div
                className="mr-4 !cursor-pointer"
                onClick={() => {
                  // dispatch(changeFlagRefresh(true))
                  // handleInsert();
                  navigate(`${window.location.pathname}/insert`);
                }}
              >
                <img
                  className="img-svg-icon-add !w-[18px]"
                  src={InsertSvg}
                  alt="delete Circle"
                />
              </div>
            </Tooltip>
          </>
        )}
        <Tooltip
          relationship="description"
          content={"Refresh"}
          positioning="after"
        >
          <div
            className="mr-3 !cursor-pointer"
            onClick={() => {
              // dispatch(changeFlagRefresh(true))
              handleRefresh();
            }}
          >
            <img
              className="img-svg-icon-add !w-[18px]"
              src={ReloadSvg}
              alt="delete Circle"
            />
          </div>
        </Tooltip>
      </>
    ) : null;
  };

  const renderSpin = () => {
    return visibleSpinner ? (
      <Spinner size="extra-small" className="mr-3" />
    ) : null;
  };

  const renderToolAction = () => {
    return visibleToolAction && pageName === "identities" ? (
      <>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <div className="mr-3 !cursor-pointer">
              <img
                className="img-svg-icon-add !w-[18px]"
                src={DeleteSvg}
                alt="delete Circle"
              />
            </div>
          </MenuTrigger>

          <MenuPopover>
            <MenuList className="!top-[15px]">
              <MenuItem
                onClick={() => {
                  dispatch(changeFlagRemoveSelected(true));
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>

        <Tooltip
          relationship="description"
          content={"Recovery"}
          positioning="after"
        >
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <div className="mr-3 !cursor-pointer">
                <img
                  className="img-svg-icon-add !w-[18px]"
                  src={RecoverySvg}
                  alt="delete Circle"
                />
              </div>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    dispatch(changeFlagRecoverySelected(true));
                  }}
                >
                  Recovery
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </Tooltip>
      </>
    ) : visibleToolAction && pageName === "relationship/:id" ? (
      <>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <div className="mr-3 !cursor-pointer">
              <img
                className="img-svg-icon-add !w-[18px]"
                src={DeleteSvg}
                alt="delete Circle"
              />
            </div>
          </MenuTrigger>

          <MenuPopover>
            <MenuList className="!top-[15px]">
              <MenuItem
                onClick={() => {
                  dispatch(changeFlagRemoveRelationship(true));
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </>
    ) : null;
  };

  return (
    <div className="flex mr-3">
      {renderSpin()}
      {renderButtonCreate()}
      {renderToolAction()}
    </div>
  );
};
export default ActionToolNavComp;
