/* eslint-disable no-useless-concat */
import { Button } from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import BackSvg from "./../../assets/back.svg";
import FilterSvg from "./../../assets/filter-footer.svg";
import NextSvg from "./../../assets/next-paging.svg";
import SortbySvg from "./../../assets/sortby.svg";
import {
  changeFlagPage,
  changeIndPathNext,
  changePage,
} from "./../../store/pagingSlice";
import { RootState } from "./../../store/store";

// import { toggleDrawer, toggleSort } from "../../store/DrawerSlice";
import { toggleDrawerFilter, toggleDrawerSort } from "../../store/DrawerSlice";
import { MD5_TEXT_ROLE } from "../../utils/constants";
import { arrPathFliter, arrPathPaing } from "../../utils/data";
import BreadcrumbComp from "../Breadcrumb";

const Pagination = () => {
  const dispatch = useDispatch();
  const { page, limit, total, indPathNext } = useSelector(
    (state: RootState) => state.paging
  );

  const { visibleFilter, visibleSort } = useSelector(
    (state: RootState) => state.drawer
  );

  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );

  const { dataBreadcrumb } = useSelector(
    (state: RootState) => state.breadcrumn
  );

  let r = localStorage.getItem(MD5_TEXT_ROLE);

  const { arrPathNextPage } = useSelector(
    (state: RootState) => state.managerelationship
  );

  return (
    <>
      <div className="flex justify-between !h-8">
        {dataBreadcrumb?.length > 0 && <BreadcrumbComp data={dataBreadcrumb} />}
        <div className="flex !h-8 items-center float-right">
          {/* @ts-ignore */}
          {arrPathPaing?.includes(pageName) && (
            <>
              <Button
                className="divBtnPaginationAction"
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-paging"
                    src={BackSvg}
                    alt="back"
                  />
                }
                onClick={() => {
                  dispatch(changePage(page - 1));
                }}
                disabled={page === 0}
              />
              {total !== 0 && (
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "6px",
                    marginRight: "6px",
                  }}
                >
                  {page + 1} /{" "}
                  {Math.floor(total / limit) + (total % limit === 0 ? 0 : 1)}
                </span>
              )}
              {total === 0 && (
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "6px",
                    marginRight: "6px",
                  }}
                >
                  1 / 1
                </span>
              )}
              <Button
                className="divBtnPaginationAction"
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-paging"
                    src={NextSvg}
                    alt="next"
                  />
                }
                onClick={() => {
                  dispatch(changePage(page + 1));
                }}
                disabled={
                  page ===
                  Math.floor(total / limit) + (total % limit === 0 ? 0 : 1) - 1
                }
                style={{ marginRight: "8px" }}
              />
            </>
          )}

          {pageName === "relationship/:id" && (
            <>
              <Button
                className="divBtnPaginationAction !mr-3"
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-paging"
                    src={BackSvg}
                    alt="back"
                  />
                }
                onClick={() => {
                  dispatch(changeIndPathNext(indPathNext - 1));
                  /* @ts-ignore */
                  dispatch(changeFlagPage("prev"));
                }}
                disabled={indPathNext === 0}
              />
              <Button
                className="divBtnPaginationAction"
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-paging"
                    src={NextSvg}
                    alt="next"
                  />
                }
                onClick={() => {
                  dispatch(changeIndPathNext(indPathNext + 1));

                  /* @ts-ignore */

                  dispatch(changeFlagPage("next"));
                }}
                disabled={indPathNext === arrPathNextPage?.length - 1}
                style={{ marginRight: "8px" }}
              />
            </>
          )}

          {/* @ts-ignore */}
          {arrPathFliter?.includes(pageName) && (
            <>
              {/* <Button
                // @ts-ignore
                className={
                  visibleSort ? "btn-active " : "" + "divBtnPaginationAction"
                }
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-filter"
                    src={SortbySvg}
                    alt="next"
                  />
                }
                onClick={() => {
                  dispatch(toggleDrawerSort(!visibleSort));
                }}
                style={{ marginRight: "4px" }}
              /> */}

              <Button
                // @ts-ignore
                className={
                  visibleFilter ? "btn-active " : "" + "divBtnPaginationAction"
                }
                appearance="subtle"
                size="medium"
                icon={
                  <img
                    className="img-svg-icon-filter"
                    src={FilterSvg}
                    alt="next"
                  />
                }
                onClick={() => {
                  dispatch(toggleDrawerFilter(!visibleFilter));
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Pagination;
