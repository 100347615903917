import { configureStore } from "@reduxjs/toolkit";
import pagingReducer from "./pagingSlice";
import ManageUserSlice from "./ManageUserSlice";
import ManageRelationshipSlice from "./ManageRelationship";
import ManageNamespaceSlice from "./ManageNamespace";
import NavSlice from "./NavSlice";

import notificationsSlice from "./notificationsSlice";
import roleSlice from "./roleSlice";
import BreadcrumbSlice from "./BreadcrumbSlice";
import pageSlice from "./pageSlice";
import DrawerSlice from "./DrawerSlice";

export const store = configureStore({
  reducer: {
    paging: pagingReducer,
    manageuser: ManageUserSlice,
    managerelationship: ManageRelationshipSlice,
    managenamespace: ManageNamespaceSlice,
    notifications: notificationsSlice,
    role: roleSlice,
    nav: NavSlice,
    breadcrumn: BreadcrumbSlice,
    page: pageSlice,
    drawer: DrawerSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
