import React from "react";


const FormNewInsert = () =>
{
  return (
    <div>
      <p>Thanh xuan</p>
    </div>
  )
}

export default FormNewInsert
// import { CloseOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons";
// import {
//   Button,
//   Card,
//   Col,
//   Divider,
//   Form,
//   Input,
//   Radio,
//   Row,
//   Select,
//   Space,
// } from "antd";
// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { getListAsync } from "../../store/ManageNamespace";

// const App: React.FC = () => {
//   const [form] = Form.useForm();
//   const dispatch = useDispatch();
//   const [arrObject, setArrObject] = useState<any>([]);
//   const [textValue, setTextValue] = useState("");
//   const [arrNamespaces, setArrNamespaces] = useState<any>([]);

//   const [flagSubjectId, setFlagSubjectId] = useState<any>(true);

//   useEffect(() => {
//     (async function () {
//       try {
//         const a: any = localStorage.getItem("arrObject");
//         setArrObject(a.split(",") ?? []);
//       } catch (e) {
//         console.error(e);
//       }
//     })();
//   }, []);

//   const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setTextValue(event.target.value);
//   };

//   const addItem = (
//     e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
//   ) => {
//     e.preventDefault();
//     if (textValue && textValue !== "") {
//       const a: any = localStorage.getItem("arrObject");
//       let b = a.split(",") ?? [];
//       let c = arrObject?.slice();

//       if (!b?.includes(textValue)) {
//         b?.unshift(textValue);
//         localStorage.setItem("arrObject", b);
//       }
//       if (!arrObject?.includes(textValue)) {
//         c?.unshift(textValue);
//         setArrObject(c);
//       }

//       setTextValue("");
//     }
//   };

//   const getNamespaces = async () => {
//     try {
//       const res = await dispatch(
//         // @ts-ignore
//         getListAsync()
//       ).unwrap();
//       if (res && Object.keys(res).length) {
//         setArrNamespaces(res?.namespaces ?? []);
//       }
//     } catch (e) {}
//   };

//   useEffect(() => {
//     getNamespaces();
//   }, []);

//   return (
//     <>
//       <Form
//         labelCol={{ span: 6 }}
//         wrapperCol={{ span: 18 }}
//         form={form}
//         name="dynamic_form_complex"
//         style={{ minWidth: 600, width: "100%" }}
//         autoComplete="off"
//         initialValues={{
//           items: [{}],
//         }}
//         className="mt-5"
//         labelAlign="left"
//       >
//         <Form.List name="items">
//           {(fields, { add, remove }) => (
//             <div
//               style={{
//                 display: "flex",
//                 rowGap: 16,
//                 flexDirection: "column",
//               }}
//             >
//               {fields.map((field, ind) => (
//                 <Card
//                   className="mx-6 my-1 rounded card-relationship-cus"
//                   size="small"
//                   title={`Relation tuple ${ind + 1}`}
//                   key={ind}
//                   extra={
//                     <>
//                       <CloseOutlined
//                         className="mr-2"
//                         onClick={() => {
//                           remove(field.name);
//                         }}
//                       />
//                       <DownOutlined />
//                     </>
//                   }
//                 >
//                   <Row className="mt-4">
//                     <Col span={11}>
//                       <Form.Item
//                         label="Namespace"
//                         //   @ts-ignore
//                         name={[field.name, "namespace"]}
//                       >
//                         <Select
//                           placeholder="Select namespace"
//                           className="div-dropdown"
//                           //   onChange={handleChange}
//                           options={arrNamespaces.map((item: any) => ({
//                             label: item?.name,
//                             value: item?.name,
//                           }))}
//                         />
//                       </Form.Item>

//                       <Form.Item
//                         label="Relation"
//                         // @ts-ignore
//                         name={[field.name, "relation"]}
//                       >
//                         <Input
//                           className="div-dropdown"
//                           placeholder="Enter relation"
//                         />
//                       </Form.Item>
//                       {/* @ts-ignore */}
//                       <Form.Item label="Object" name={[field.name, "object"]}>
//                         <Select
//                           className="div-dropdown"
//                           placeholder="Select object"
//                           dropdownRender={(menu) => (
//                             <>
//                               {menu}
//                               <Divider style={{ margin: "8px 0" }} />
//                               <Space style={{ padding: "0 8px 4px" }}>
//                                 <Input
//                                   placeholder="Please enter item"
//                                   value={textValue}
//                                   onChange={onNameChange}
//                                   onKeyDown={(e) => e.stopPropagation()}
//                                 />
//                                 <Button
//                                   type="text"
//                                   icon={
//                                     <PlusOutlined className="!text-red-300" />
//                                   }
//                                   onClick={addItem}
//                                 >
//                                   Add item
//                                 </Button>
//                               </Space>
//                             </>
//                           )}
//                           options={arrObject.map((item: any) => ({
//                             label: item,
//                             value: item,
//                           }))}
//                         />
//                       </Form.Item>
//                     </Col>
//                     <Col
//                       span={2}
//                       className="flex flex-col items-center text-center"
//                     >
//                       <Divider
//                         type="vertical"
//                         className="h-full !bg-[#353B4F]"
//                       />
//                     </Col>
//                     <Col span={11}>
//                       <Radio.Group
//                         value={flagSubjectId ? "Subject id" : "Subject set"}
//                         onChange={(e: any) => {
//                           setFlagSubjectId(
//                             e.target.value === "Subject id" ? true : false
//                           );
//                         }}
//                       >
//                         <Radio value="Subject id">Subject id</Radio>
//                         <Radio value="Subject set">Subject set</Radio>
//                       </Radio.Group>

//                       {flagSubjectId ? (
//                         <Form.Item
//                           label="Subject id"
//                           // @ts-ignore
//                           name={[field.name, "subject_id"]}
//                           className="mt-4"
//                           rules={[
//                             {
//                               required: true,
//                               message: "Not empty",
//                             },
//                           ]}
//                         >
//                           <Input
//                             className="div-dropdown"
//                             placeholder="Enter subject id"
//                           />
//                         </Form.Item>
//                       ) : (
//                         <>
//                           <Form.Item
//                             label="Namespace"
//                             //   @ts-ignore
//                             name={[field.name, "subject_set.namespace"]}
//                             className="mt-4"
//                             rules={[
//                               {
//                                 required: true,
//                                 message: "Not empty",
//                               },
//                             ]}
//                           >
//                             <Select
//                               className="div-dropdown"
//                               placeholder="Select namespace's subject set"
//                               //   onChange={handleChange}
//                               options={arrNamespaces.map((item: any) => ({
//                                 label: item?.name,
//                                 value: item?.name,
//                               }))}
//                             />
//                           </Form.Item>
//                           <Form.Item
//                             label="Relation"
//                             // @ts-ignore
//                             name={[field.name, "subject_set.relation"]}
//                             rules={[
//                               {
//                                 required: true,
//                                 message: "Not empty",
//                               },
//                             ]}
//                           >
//                             <Input
//                               className="div-dropdown"
//                               placeholder="Enter relation's subject set"
//                             />
//                           </Form.Item>
//                           {/* @ts-ignore */}
//                           <Form.Item
//                             label="Object"
//                             name={[field.name, "subject_set.object"]}
//                             rules={[
//                               {
//                                 required: true,
//                                 message: "Not empty",
//                               },
//                             ]}
//                           >
//                             <Select
//                               className="div-dropdown"
//                               placeholder="Select object's subject set"
//                               dropdownRender={(menu) => (
//                                 <>
//                                   {menu}
//                                   <Divider style={{ margin: "8px 0" }} />
//                                   <Space style={{ padding: "0 8px 4px" }}>
//                                     <Input
//                                       placeholder="Please enter item"
//                                       value={textValue}
//                                       onChange={onNameChange}
//                                       onKeyDown={(e) => e.stopPropagation()}
//                                     />
//                                     <Button
//                                       type="text"
//                                       icon={
//                                         <PlusOutlined className="!text-red-300" />
//                                       }
//                                       onClick={addItem}
//                                     >
//                                       Add item
//                                     </Button>
//                                   </Space>
//                                 </>
//                               )}
//                               options={arrObject.map((item: any) => ({
//                                 label: item,
//                                 value: item,
//                               }))}
//                             />
//                           </Form.Item>
//                         </>
//                       )}
//                     </Col>
//                   </Row>
//                 </Card>
//               ))}

//               <Button
//                 className="!w-[150px]"
//                 type="dashed"
//                 onClick={() => add()}
//                 block
//               >
//                 + Add relation tuple
//               </Button>
//             </div>
//           )}
//         </Form.List>
//       </Form>

//       <div className="flex absolute bottom-20 right-4 gap-x-2">
//         <Button>Cancel</Button>
//         <Button type="primary">Insert</Button>
//         {/* <Button
//           appearance="outline"
//           className="h-8 !bg-ink-400 border border-white/0.03"
//           onClick={() => navigate(`/relationship/${id}`)}
//           disabled={visibleSpinner}
//         >
//           {t("Cancel")}
//         </Button>

//         <Button
//           appearance="outline"
//           className="h-8 !bg-primary-500 border border-white/0.03 !text-black"
//           disabled={checkBtnSave()}
//           onClick={() => handleAdd()}
//         >
//           {t("Insert")}
//         </Button> */}
//       </div>
//     </>
//   );
// };

// export default App;
