import React, { useEffect } from "react";
import FormAddComp from "../../components/Identities/FormAdd";
import { changeName, changePath } from "../../store/pageSlice";
import { useDispatch } from "react-redux";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";

const dataBread = [
  {
    key: 1,
    value: "Identities",
    path: "identities",
  },
  {
    key: 2,
    value: "Create",
    // path: "add",
  },
];

const AddIdentitiesPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changePath("/identities/add"));
    dispatch(changeName("identities/add"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);
  return <FormAddComp />;
};

export default AddIdentitiesPage;
