// import { Configuration } from "@ory/kratos-client";

import axios from './utils/axios';

// interface JSONConfig {
//   kratosAdminURL?: string;
//   kratosPublicURL?: string;
//   reverseProxy?: boolean;
//   ip?: string;
//   endpoint?: string;
//   ipLogin?: string;
//   ipAccount?: string;
//   ketoAdminUrl?: string;
//   ketoPublicUrl?: string;
// }

// async function loadConfig() {
//   // const data = await fetch(process.env.REACT_APP_CFG_URL ?? "/config.json");
//   const data = await fetch(process.env.REACT_APP_CFG_URL);

//   console.log(await data.json(), "data.json");

//   const JSON_CONFIG = await data.json();

//   console.log(JSON_CONFIG, "suan");

//   if (JSON_CONFIG.reverseProxy) {
//     // every admin-url starts with /admin, so there is no need to have /admin/admin. There is a url rewrite in the nginx config
//     JSON_CONFIG.kratosAdminURL = "/api";
//     JSON_CONFIG.kratosPublicURL = "/api/public";
//   }

//   return JSON_CONFIG;
// }

// export async function getKratosConfig() {
//   const configJSON = await loadConfig();

//   return {
//     kratosAdminURL: configJSON.kratosAdminURL,
//     kratosPublicURL: configJSON.kratosPublicURL,
//     reverseProxy: configJSON.reverseProxy,
//     ip: configJSON.ip,

//     ipAccount: configJSON.ipAccount,
//     ketoAdminUrl: configJSON.ketoAdminUrl,
//     ketoPublicUrl: configJSON.ketoPublicUrl,
//   };
// }

// export async function getKratosAdminConfig() {
//   const configJSON = await loadConfig();
//   return {
//     version: "2.3.2",
//     supportedVersion: "v1.1.0",
//     kratosAdminURL: configJSON.kratosAdminURL,
//     kratosPublicURL: configJSON.kratosPublicURL,
//     reverseProxy: configJSON.reverseProxy,
//     ip: configJSON.ip,

//     ipAccount: configJSON.ipAccount,
//     ketoAdminUrl: configJSON.ketoAdminUrl,
//     ketoPublicUrl: configJSON.ketoPublicUrl,
//   };
// }

export async function loadConfig(url: any) {
  const data = await fetch(url);

  console.log(data, "data");
  const JSON_CONFIG: any = await data.json();

  console.log(JSON_CONFIG, "JSON_CONFIG");

  return JSON_CONFIG;
}
