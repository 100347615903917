import React, { useState } from "react";
import {
  Input,
  Label,
  makeStyles,
  shorthands,
  InputProps,
  ButtonProps,
  Button,
} from "@fluentui/react-components";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { arrPathVisibleSeachBar } from "../../utils/data";
import searchSvg from "../../assets/images/search.svg";
import DismissSvg from "./../../assets/Dismiss.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  changeFlagFilter,
  changeFlagSearchBar,
  changeKeyword,
} from "../../store/ManageRelationship";

interface IStyledInput extends InputProps {
  err?: string;
}

// @ts-ignore
interface IButtonCus extends ButtonProps {
  visible?: boolean;
  onChangeVal?: () => void;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("2px"),
    marginRight: "24px",
  },

  input: {
    ...shorthands.borderRadius("3px"),
    backgroundColor: "272D3C",
    ...shorthands.borderColor("none"),
    cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: "rgb(57, 57, 57, 1)",
    // },

    // "&:focus-within": {
    //   backgroundColor: "rgba(28, 28, 28, 1)",
    // },
  },
});

const StyledFluentInput: React.FC<IStyledInput> = styled(Input)`
  width: 30rem;
  background-color: #272d3c;
  padding-left: 1px;
  padding-right: 1px;
  border: none !important;
  border-bottom-color: ${(props) =>
    props?.err && props?.err !== "" ? "rgba(221, 44, 0, 1)" : "none"};

  &:focus-within {
    border-bottom-color: transparent !important;
    border-bottom: ${(props) =>
      props?.err && props?.err !== ""
        ? "1px solid rgba(221, 44, 0, 1)"
        : "1px solid rgba(96, 205, 255, 1)"};

    &:after {
      border-bottom-color: transparent !important;
      border-bottom: ${(props) =>
        props?.err && props?.err !== ""
          ? "1px solid rgba(221, 44, 0, 1)"
          : "1px solid rgba(96, 205, 255, 1)"};
    }
  }

  &:hover {
    border-bottom-color: transparent !important;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom: ${(props) =>
      props?.err && props?.err !== ""
        ? "1px solid rgba(221, 44, 0, 1)"
        : "1px solid rgba(149, 149, 149, 1)"};

    &:after {
      border-bottom-color: transparent !important;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      border-bottom: ${(props) =>
        props?.err && props?.err !== ""
          ? "1px solid rgba(221, 44, 0, 1)"
          : "1px solid rgba(149, 149, 149, 1)"};
    }
  }
`;

const ClearButton = (props: IButtonCus) => {
  return props.visible ? (
    <Button
      {...props}
      appearance="transparent"
      icon={<img className="dismiss-svg-icon" src={DismissSvg} alt="dismiss" />}
      size="small"
      onClick={props?.onChangeVal}
    />
  ) : null;
};

const SearchButton = (props: IButtonCus) => {
  return (
    <Button
      className="!h-8 !w-8 !max-w-[32px]"
      {...props}
      appearance="transparent"
      icon={<img className="img-svg-icon" src={searchSvg} alt="search" />}
      size="small"
      onClick={props?.onChangeVal}
    />
  );
};

const SearchComponent = (props: any) => {
  const styles: any = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let pageName: any = useSelector((state: RootState) => state.page?.name);

  const relationshipState = useSelector(
    (state: RootState) => state.managerelationship
  );

  let keyword =
    pageName === "relationship/:id" ? relationshipState?.keyword : "";

  const onSearchTextChange = (val: string | any) => {
    if (pageName === "relationship/:id") {
      dispatch(changeKeyword(val ? val.trim() : val));
    }
  };

  const handleSearch = () => {
    if (pageName === "relationship/:id") {
      dispatch(changeFlagSearchBar(true));
    }
  };

  return (
    <>
      {arrPathVisibleSeachBar?.includes(pageName) && (
        <div style={props.stylesDiv} className={styles?.root} id="input-fluent">
          {props?.label && <Label size="medium">{props?.label}</Label>}

          <StyledFluentInput
            key={"StyledFluentInput"}
            type="text"
            id={props?.id}
            className={styles?.input + " px-2"}
            appearance="filled-lighter"
            value={keyword}
            onChange={(_e: any, data: any) => {
              onSearchTextChange(data.value);
            }}
            disabled={props?.disabled}
            contentAfter={
              <div className="flex">
                <ClearButton
                  aria-label="Clear text"
                  // visible={searchText && searchText.trim() ? true : false}
                  // onChangeVal={() => {
                  //   handleCancelSearch();
                  // }}
                />
              </div>
            }
            contentBefore={
              <>
                <SearchButton
                  aria-label="Search text"
                  onChangeVal={() => {
                    handleSearch();
                  }}
                />
              </>
            }
            placeholder={t("Search")}
            onKeyPress={(e: any) => {
              if (e.charCode === 13 && pageName !== "mainMap") {
                handleSearch();
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default SearchComponent;
