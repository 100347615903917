import * as React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbButton,
} from "@fluentui/react-breadcrumb-preview";
import { Link } from "react-router-dom";

const BreadcrumbComp = (props: any) => {
  return (
    <>
      <Breadcrumb
        aria-label="Small Breadcrumb"
        size="large"
        style={{
          ...props?.style,
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          textTransform: "capitalize",
        }}
      >
        {props?.data.map((item: any, index: any) => (
          <React.Fragment key={`item-${item.key}`}>
            <BreadcrumbItem>
              {item?.path ? (
                <Link to={`/${item?.path}`}>
                  <BreadcrumbButton
                    className={index === 0 ? "!pl-0" : ""}
                    icon={item.icon}
                    current={props?.data.length - 1 === index}
                    style={{ fontSize: "14px", textTransform: "capitalize" }}
                  >
                    {item.value}
                  </BreadcrumbButton>
                </Link>
              ) : (
                <BreadcrumbButton
                  className={index === 0 ? "!pl-0" : ""}
                  icon={item.icon}
                  current={props?.data.length - 1 === index}
                  style={{ fontSize: "14px", textTransform: "capitalize" }}
                >
                  {item.value}
                </BreadcrumbButton>
              )}
            </BreadcrumbItem>
            {!(props?.data.length - 1 === index) && <BreadcrumbDivider />}
          </React.Fragment>
        ))}
      </Breadcrumb>
    </>
  );
};

export default BreadcrumbComp;
