import React, { useEffect } from "react";
import FormEditComp from "../../components/Identities/FormEdit";
import { useDispatch } from "react-redux";
import { changeName, changePath } from "../../store/pageSlice";
import { useLocation } from "react-router-dom";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";

const EditIdentitiesPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const dataBread = [
    {
      key: 1,
      value: "Identities",
      path: "identities",
    },
    {
      key: 2,
      value: location?.state?.itemEdit?.traits?.name?.first
        ? `Edit ${location?.state?.itemEdit?.traits?.name?.first} ${location?.state?.itemEdit?.traits?.name?.last}`
        : `Edit ${location?.state?.itemEdit?.traits?.email}`,
    },
  ];

  useEffect(() => {
    dispatch(changePath("/identities/edit"));
    dispatch(changeName("identities/edit"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);
  return <FormEditComp />;
};

export default EditIdentitiesPage;
