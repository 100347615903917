import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface DrawerFilterState {
  visibleFilter: boolean;
}

export interface DrawerSortState {
  visibleSort: boolean;
}

export interface DrawerShowInfoRecoveryState {
  visibleRecovery: boolean;
}

const initialState: DrawerSortState &
  DrawerFilterState &
  DrawerShowInfoRecoveryState = {
  visibleRecovery: false,
  visibleFilter: false,
  visibleSort: false,
};

export const DrawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    resetAllStateDrawer: (state, action) => {
      state.visibleFilter = false;
      state.visibleRecovery = false;
      state.visibleSort = false;
    },

    toggleDrawerFilter: (state, action: PayloadAction<boolean>) => {
      state.visibleFilter = action.payload;
      state.visibleRecovery = false;
      state.visibleSort = false;
    },

    toggleDrawerSort: (state, action: PayloadAction<boolean>) => {
      state.visibleFilter = false;
      state.visibleRecovery = false;
      state.visibleSort = action.payload;
    },

    toggleDrawerRecovery: (state, action: PayloadAction<boolean>) => {
      state.visibleFilter = false;
      state.visibleRecovery = action.payload;
      state.visibleSort = false;
    },
  },

  extraReducers: (builder) => {
    // builder;
  },
});

export const {
  resetAllStateDrawer,
  toggleDrawerFilter,
  toggleDrawerRecovery,
  toggleDrawerSort,
} = DrawerSlice.actions;

export default DrawerSlice.reducer;
