/* eslint-disable jsx-a11y/alt-text */
import {
  Button,
  Divider,
  Dropdown,
  Input,
  Option,
  Radio,
  RadioGroup,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store/store";
import { arrRelation } from "../../utils/data";
import LoadingGlobalComp from "../Loading/LoadingGlobal";

import Autocomplete from "react-autocomplete";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  addRelationshipAsync,
  deleteRelationshipAsync,
  getListAsync,
} from "../../store/ManageRelationship";
import { changeVisibleSpinner } from "../../store/NavSlice";

const FormEditComp = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();

  const [object, setObject] = useState<any>(location?.state?.itemEdit?.object);
  const [relation, setRelation] = useState<any>(
    location?.state?.itemEdit?.relation
  );
  const [subjectId, setSubjectId] = useState<any>(
    location?.state?.itemEdit?.subject_id
  );

  const [objectSubjectSet, setObjectSubjectSet] = useState<any>(
    location?.state?.itemEdit?.subject_set?.object
  );
  const [relationSubjectSet, setRelationSubjectSet] = useState<any>(
    location?.state?.itemEdit?.subject_set?.relation
  );

  const [flagAddSubjectSet, setFlagAddSubjectSet] = useState<boolean>(
    location?.state?.itemEdit?.subject_set?.object ? true : false
  );

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const [arrObject, setArrObject] = useState<any>([]);

  const { dispatchToast } = useToastController(toasterId);

  const notify = (msg: any, type: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>Notification</ToastTitle>
        <ToastBody>{msg}</ToastBody>
      </Toast>,
      { timeout: 5000, intent: type }
    );
  };

  useEffect(() => {
    dispatch(
      changeDataBreadcrumb([
        {
          key: 1,
          value: `Relationship ${id}`,
          path: "./relationship/" + id,
        },
        {
          key: 2,
          value: "Edit",
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, [id]);

  useEffect(() => {
    (async function () {
      try {
        const a: any = localStorage.getItem("arrObject");
        setArrObject(a.split(",") ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const handleAdd = async () => {
    try {
      dispatch(changeVisibleSpinner(true));
      const resDel = await dispatch(
        // @ts-ignore
        deleteRelationshipAsync(
          // @ts-ignore
          location?.state?.itemEdit?.subject_set?.object
            ? {
                namespace: id,
                object: location?.state?.itemEdit?.object,
                relation: location?.state?.itemEdit?.relation,
                "subject_set.namespace":
                  location?.state?.itemEdit?.subject_set?.namespace,
                "subject_set.object":
                  location?.state?.itemEdit?.subject_set?.object,
                "subject_set.relation":
                  location?.state?.itemEdit?.subject_set?.relation,
              }
            : location?.state?.itemEdit
        )
      ).unwrap();

      if (resDel?.status === 204) {
        try {
          dispatch(changeVisibleSpinner(true));

          let payload = flagAddSubjectSet
            ? {
                namespace: id,
                object: object,
                relation: relation,
                subject_set: {
                  namespace: id,
                  object: objectSubjectSet,
                  relation: relationSubjectSet,
                },
              }
            : {
                namespace: id,
                object: object,
                relation: relation,
                subject_id: subjectId,
              };

          let a = await checkDuplicateRelations(payload);

          if (!a) {
            const res = await dispatch(
              // @ts-ignore
              addRelationshipAsync(payload)
            ).unwrap();

            if (res?.status === 201 || res?.status === 200) {
              if (!arrObject?.includes(object)) {
                arrObject?.unshift(object);
                localStorage.setItem("arrObject", arrObject);
              }
              notify("Update relationship successfully", "success");
              setTimeout(() => {
                navigate(`/relationship/${id}`);
                // @ts-ignore
                dispatch(changeVisibleSpinner(false));
              }, 1000);
            } else {
              notify("Update relationship failed", "error");
              setTimeout(() => {
                dispatch(changeVisibleSpinner(false));
              }, 1000);
            }
          } else {
            notify("Duplicate relationship", "error");
            setTimeout(() => {
              dispatch(changeVisibleSpinner(false));
            }, 1000);
          }
        } catch (e) {
          console.error(e);
          notify("Error", "error");
          setTimeout(() => {
            dispatch(changeVisibleSpinner(false));
          }, 1000);
        }
      } else {
        notify("Error", "error");
        dispatch(changeVisibleSpinner(false));
      }
    } catch (e) {
      console.error(e);
      notify("Error", "error");
      dispatch(changeVisibleSpinner(false));
    }
  };

  const checkBtnSave = () => {
    let a = false;
    if (visibleSpinner) {
      a = true;
    }
    if (flagAddSubjectSet && (!objectSubjectSet || !relationSubjectSet)) {
      a = true;
    }
    if (!flagAddSubjectSet && !subjectId) {
      a = true;
    }
    return a;
  };

  const checkDuplicateRelations = async (payload: any) => {
    let newPay: any = {};

    if (payload?.subject_set?.object) {
      newPay = {
        namespace: id,
        object: payload?.object,
        relation: payload?.relation,
        "subject_set.namespace": payload?.subject_set?.namespace,
        "subject_set.object": payload?.subject_set?.object,
        "subject_set.relation": payload?.subject_set?.relation,
      };
    } else {
      newPay = payload;
    }
    let a = false;
    //@ts-ignore
    const res = await dispatch(getListAsync(newPay)).unwrap();
    if (res?.relation_tuples?.length > 0) {
      a = true;
    }

    return a;
  };

  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}

      <div className="div-form-storage">
        <div className="flex w-full justify-between">
          <div style={{ width: "95%", marginRight: "35px" }}>
            <p className="mb-2 mt-[37px]">Namespaces</p>
            <Dropdown
              placeholder="Select schema"
              className="div-dropdown"
              value={id}
              disabled
            >
              {[]?.map((item: any) => (
                <Option value={item} key={item?.id}>
                  {item?.id}
                </Option>
              ))}
            </Dropdown>

            <p className="mb-2 mt-6">Object</p>

            <div className="autocomplete-wrapper div-dropdown">
              <Autocomplete
                inputProps={{
                  placeholder: "Select object",
                  multiple: true,
                }}
                value={object}
                items={arrObject}
                getItemValue={(item) => item}
                shouldItemRender={(item: any, val: any) => {
                  return item.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                }}
                renderMenu={(item) => <div className="dropdown">{item}</div>}
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${isHighlighted ? "selected-item" : ""}`}
                  >
                    {item}
                  </div>
                )}
                onChange={(event: any, val: any) => setObject(val)}
                onSelect={(val: any) => setObject(val)}
              />
            </div>

            <p className="mb-2 mt-6">Relation</p>
            <div className="autocomplete-wrapper div-dropdown">
              <Autocomplete
                inputProps={{
                  placeholder: "Select relation",
                }}
                value={relation}
                items={arrRelation}
                getItemValue={(item) => item}
                shouldItemRender={(item: any, val: any) => {
                  return item.toLowerCase().indexOf(val.toLowerCase()) !== -1;
                }}
                renderMenu={(item) => <div className="dropdown">{item}</div>}
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${isHighlighted ? "selected-item" : ""}`}
                  >
                    {item}
                  </div>
                )}
                onChange={(event: any, val: any) => setRelation(val)}
                onSelect={(val: any) => setRelation(val)}
              />
            </div>
          </div>
          <Divider vertical />
          <div style={{ width: "95%", marginLeft: "35px" }}>
            <RadioGroup
              value={flagAddSubjectSet ? "subject_set" : "subject_id"}
              onChange={(_, data) => {
                setFlagAddSubjectSet(
                  data?.value === "subject_set" ? true : false
                );
              }}
              layout="horizontal"
              className="mb-1"
            >
              <Radio value="subject_id" label="Subject id" />
              <Radio value="subject_set" label="Subject set" />
            </RadioGroup>
            {flagAddSubjectSet ? (
              <>
                <p className="mb-2">
                  Namespaces <span style={{ color: "red" }}>*</span>
                </p>
                <Dropdown
                  placeholder="Select schema"
                  className="div-dropdown"
                  value={id}
                  disabled
                >
                  {[]?.map((item: any) => (
                    <Option value={item} key={item?.id}>
                      {item?.id}
                    </Option>
                  ))}
                </Dropdown>

                <p className="mb-2 mt-6">
                  Object <span style={{ color: "red" }}>*</span>
                </p>
                <div className="autocomplete-wrapper div-dropdown">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Select object",
                    }}
                    value={objectSubjectSet}
                    items={arrObject}
                    getItemValue={(item) => item}
                    shouldItemRender={(item: any, val: any) => {
                      return (
                        item.toLowerCase().indexOf(val.toLowerCase()) !== -1
                      );
                    }}
                    renderMenu={(item) => (
                      <div className="dropdown">{item}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    onChange={(event: any, val: any) =>
                      setObjectSubjectSet(val)
                    }
                    onSelect={(val: any) => setObjectSubjectSet(val)}
                  />
                </div>

                <p className="mb-2 mt-6">
                  Relation <span style={{ color: "red" }}>*</span>
                </p>
                <div className="autocomplete-wrapper div-dropdown">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Select relation",
                    }}
                    value={relationSubjectSet}
                    items={arrRelation}
                    getItemValue={(item) => item}
                    shouldItemRender={(item: any, val: any) => {
                      return (
                        item.toLowerCase().indexOf(val.toLowerCase()) !== -1
                      );
                    }}
                    renderMenu={(item) => (
                      <div className="dropdown">{item}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    onChange={(event: any, val: any) =>
                      setRelationSubjectSet(val)
                    }
                    onSelect={(val: any) => setRelationSubjectSet(val)}
                  />
                </div>
              </>
            ) : (
              <>
                <p className="mb-2">
                  Subject Id <span style={{ color: "red" }}>*</span>
                </p>
                <Input
                  placeholder="Enter subject id"
                  className="div-dropdown"
                  value={subjectId}
                  onChange={(ev: any, data: any) => {
                    setSubjectId(data?.value);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex absolute bottom-4 right-4 gap-x-2">
        <Button
          appearance="outline"
          className="h-8 !bg-ink-400 border border-white/0.03"
          onClick={() => navigate(`/relationship/${id}`)}
          disabled={visibleSpinner}
        >
          {t("Cancel")}
        </Button>

        <Button
          appearance="outline"
          className="h-8 !bg-primary-500 border border-white/0.03 !text-black"
          disabled={checkBtnSave()}
          onClick={() => handleAdd()}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default FormEditComp;
