import { createSlice } from "@reduxjs/toolkit";

export interface NavState {
  visibleBtnCreate?: boolean;
  pathCreate?: string;
  stateItem?: any;
  visibleSpinner?: boolean;
  visibleToolAction?: boolean;
}

const initialState: NavState = {
  visibleBtnCreate: false,
  pathCreate: "",
  stateItem: undefined,
  visibleSpinner: false,
  visibleToolAction: false,
};

export const NavSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    changeStateBtnCreate: (state, action) => {
      state.visibleBtnCreate = action?.payload?.flag;
      state.pathCreate = action?.payload?.path;
      state.stateItem = action?.payload?.stateItem;
    },
    changeVisibleSpinner: (state, action) => {
      state.visibleSpinner = action?.payload;
    },
    changeVisibleToolAction: (state, action) => {
      state.visibleToolAction = action?.payload;
    },
  },

  extraReducers: (builder) => {
    // builder;
  },
});

export const {
  changeStateBtnCreate,
  changeVisibleSpinner,
  changeVisibleToolAction,
} = NavSlice.actions;

export default NavSlice.reducer;
