import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface PagingState {
  page: number;
  limit: number;
  total: number;
  pageTokens: Array<string>;
  nextPageToken?: string | any;
}

export interface DrawerCartState {
  visibleCart: boolean;
}

export interface FilterState {
  visible: boolean;
}
export interface SortState {
  visibleSort: boolean;
}

export interface PagingNextPage {
  indPathNext: any;
  flagPage?: any;
}

const initialState: PagingState &
  FilterState &
  DrawerCartState &
  SortState &
  PagingNextPage = {
  page: 0,
  limit: 8,
  visible: false,
  total: 0,
  visibleCart: false,
  pageTokens: [],
  nextPageToken: undefined,
  visibleSort: false,
  indPathNext: 0,
  flagPage: "next",
};

export const pagingSlice = createSlice({
  name: "paging",
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.page = action?.payload;
    },

    changeIndPathNext: (state, action: PayloadAction<number>) => {
      state.indPathNext = action.payload;
    },
    resetNextPage: (state, action: any) => {
      state.indPathNext = 0;
      state.flagPage = "next";
    },
    changeFlagPage: (state, action?: any) => {
      state.flagPage = action.payload;
    },
    changeNextPageToken: (state, action: PayloadAction<any>) => {
      state.nextPageToken = action?.payload;
      let tokensCopy = state.pageTokens.slice();
      if (
        (!action?.payload && tokensCopy.length === 0) ||
        !tokensCopy.includes(action?.payload)
      )
        tokensCopy.push(action?.payload);
      state.pageTokens = tokensCopy;
    },
    changeTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    toggleDrawer: (state, action: PayloadAction<boolean>) => {
      state.visible = !state.visible;
    },
    toggleSort: (state, action: PayloadAction<boolean>) => {
      state.visibleSort = !state.visibleSort;
    },
    resetState: (state) => {
      state.page = 0;
      state.total = 0;
      state.visible = false;
      state.nextPageToken = null;
      state.pageTokens = [];
      state.indPathNext = 0;
      state.flagPage = "next";
    },
  },
});

export const {
  changePage,
  toggleDrawer,
  changeTotal,
  resetState,
  changeNextPageToken,
  toggleSort,
  changeIndPathNext,
  changeFlagPage,
  resetNextPage,
} = pagingSlice.actions;

export default pagingSlice.reducer;
