import axios from '../../utils/axios';


export async function getListUser(params?: any) {
  return axios.get(
    `${localStorage.getItem("kratosAdminURL")}/admin/identities`,
    {
      params: { ...params },
    }
  );
}

export async function deleteUser(id: string) {
  return axios.delete(
    `${localStorage.getItem("kratosAdminURL")}/admin/identities/${id}`
  );
}

export async function getSchemas(params?: any) {
  return axios.get(`${localStorage.getItem("kratosPublicURL")}/schemas`, {
    params: { ...params },
  });
}

export async function getSchemasByIdSchema(payload?: any) {
  return axios.get(
    `${localStorage.getItem("kratosPublicURL")}/schemas/${payload?.id}`
  );
}
export async function addUser(payload: any) {
  return axios.post(
    `${localStorage.getItem("kratosAdminURL")}/admin/identities`,
    payload
  );
}

export async function updateUser(payload: any) {
  return axios.put(
    `${localStorage.getItem("kratosAdminURL")}/admin/identities/${payload?.id}`,
    payload
  );
}

export async function deleteManyUser(payload: string) {
  return axios.patch(
    `${localStorage.getItem("kratosAdminURL")}/admin/identities`,
    payload
  );
}

export async function createRecoveryLink(payload: any) {
  return axios.post(
    `${localStorage.getItem("kratosAdminURL")}/admin/recovery/link`,
    payload
  );
}

export async function createRecoveryCode(payload: any) {
  return axios.post(
    `${localStorage.getItem("ip")}/oa/admin/recovery/code`,
    payload
  );
}
export async function setUser(user_id: any) {
  return axios.post(
    `${localStorage.getItem("ip")}/admin/users/${user_id}/roles/roles/as_user/`
  );
}
export async function setStaff(user_id: any) {
  return axios.post(
    `${localStorage.getItem("ip")}/admin/users/${user_id}/roles/staff/`
  );
}

export async function setAdmin(user_id: any) {
  return axios.post(
    `${localStorage.getItem("ip")}/admin/users/${user_id}/roles/as_admin/`
  );
}

export async function applyAdminRoles(id: string) {

  // admin
  return axios.post(
    `${localStorage.getItem("ip")}/admin/users/${id}/roles/apply_admin_roles/`
  );
}

export async function applyUserRoles(id: string) {
 
  // admin
  return axios.post(
    `${localStorage.getItem("ip")}/admin/users/${id}/roles/apply_user_roles/`
  );
}

export async function getRolesUser(user_id: string) {
  return axios.get(
    `${localStorage.getItem("ip")}/admin/users/${user_id}/groups/`
  );
}

export async function getInfor() {
  //user+ admin

  return axios.get(
    `${localStorage.getItem("kratosPublicURL")}/sessions/whoami`
  );
}

export async function getMyRoles() {
  return axios.get(`${localStorage.getItem("ip")}/users/groups/`);
}

export async function getInforUserById(payload: any) {
  //user+ admin

  return axios.get(
    `${localStorage.getItem("kratosAdminURL")}/admin/identities/${payload?.id}`
  );
}
