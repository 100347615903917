import {
  Button,
  Divider,
  Dropdown,
  Field,
  Input,
  Option,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { changeVisibleSpinner } from "../../store/NavSlice";
import { RootState } from "../../store/store";
import LoadingGlobalComp from "../Loading/LoadingGlobal";
import { addUserAsync, getSchemasAsync } from "./../../store/ManageUserSlice";
import EditTraitsComp from "./EditTraits";

const FormAddComp = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [arrSchema, setArrSchema] = useState<any>([]);
  const [schemaId, setSchemaId] = useState<any>(null);
  const [schema, setSchema] = useState<any>(undefined);
  const [pwd, setPwd] = useState<any>(null);

  const [values, setValues] = useState<any>();

  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { dispatchToast } = useToastController(toasterId);

  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  const notify = (msg: any, type: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>Notification</ToastTitle>
        <ToastBody>{msg}</ToastBody>
      </Toast>,
      { timeout: 5000, intent: type }
    );
  };

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        const res = await dispatch(
          // @ts-ignore
          getSchemasAsync({})
        ).unwrap();

        setArrSchema(res?.data ?? []);
        setSchemaId(res?.data?.[0]?.id);
        setSchema(res?.data?.[0]?.schema);
        console.log(res?.data?.[0]?.id);
        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, []);

  const handleAdd = async () => {
    try {
      dispatch(changeVisibleSpinner(true));

      const newPay = {
        traits: values?.traits,
        schema_id: schemaId,
        metadata_admin: values?.adminMetadata,
        metadata_public: values?.publicMetadata,
        credentials: {
          password: {
            config: {
              password: pwd,
            },
          },
        },
      };

 
      const res = await dispatch(
        // @ts-ignore
        addUserAsync({ ...newPay })
      ).unwrap();

      if (res?.status === 201 || res?.status === 200) {
        notify("Add identities successfully", "success");
        setTimeout(() => {
          navigate(`/identities/${res?.data?.id}`, {
            state: { itemEdit: res?.data },
          });
          // @ts-ignore
          dispatch(changeVisibleSpinner(false));
        }, 1000);
      } else {
        notify("Add identities failed", "error");
        dispatch(changeVisibleSpinner(false));
      }
    } catch (e) {
      console.error(e);
      dispatch(changeVisibleSpinner(false));
    }
  };

  console.log(values, 'value')

  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}

      <div className="div-form-storage">
        <div className="flex w-full justify-between">
          <div style={{ width: "95%", marginRight: "35px" }}>
            <p className="title-div-form">Schema</p>
            <Dropdown
              placeholder="Select schema"
              className="div-dropdown"
              onOptionSelect={(e: any, data: any) => {
                setSchema(data?.optionValue?.schema);
                setSchemaId(data?.optionValue?.id);
              }}
              selectedOptions={[schemaId]}
              value={schemaId}
            >
              {arrSchema?.map((item: any) => (
                <Option value={item} key={item?.id}>
                  {item?.id}
                </Option>
              ))}
            </Dropdown>

            <pre className="schemaPre mt-3">
              {JSON.stringify(schema, null, 2)}
            </pre>
          </div>
          <Divider vertical />
          <div style={{ width: "95%", marginLeft: "35px" }}>
            {schema?.properties && (
              <>
                <EditTraitsComp
                  modi="new"
                  schema={schema}
                  schemaId={schemaId}
                  setValues={(val: any) => setValues(val)}
                  values={values}
                />
              </>
            )}

            <Field label="Password" required={true} className="mb-2">
              <Input
                onChange={(e) => setPwd(e?.target?.value)}
                className="div-dropdown"
              ></Input>
            </Field>
          </div>
        </div>
      </div>

      <div className="flex absolute bottom-4 right-4 gap-x-2">
        <Button
          appearance="outline"
          className="h-8 !bg-ink-400 border border-white/0.03"
          onClick={() => navigate(`/identities`)}
          disabled={visibleSpinner}
        >
          {t("Cancel")}
        </Button>

        <Button
          appearance="outline"
          className="h-8 !bg-primary-500 border border-white/0.03 !text-black"
          disabled={visibleSpinner}
          onClick={() => handleAdd()}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default FormAddComp;
