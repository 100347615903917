import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeName, changePath } from "../../../store/pageSlice";
import { resetState } from "../../../store/pagingSlice";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { resetAllStateDrawer } from "../../../store/DrawerSlice";

const PageNotFound = (props: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changePath("/404-page"));
    dispatch(changeName("404-page"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
      // @ts-ignore
      dispatch(resetState({}));

      dispatch(resetAllStateDrawer({}));
    };
  }, []);
  return (
    <div className="bg-s-background2 w-full pb-16 md:px-0 h-screen flex items-center justify-center">
      <div className="bg-s-background2  flex flex-col items-center justify-center pb-4 md:pb-8 lg:px-24 py-8">
        <svg
          width={480}
          height={411}
          viewBox="0 0 480 411"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M257.367 6.50371C260.035 4.9634 263.322 4.9634 265.99 6.5037L418.509 94.5608C421.177 96.1011 422.821 98.9477 422.821 102.028V278.143C422.821 281.223 421.177 284.07 418.509 285.61L265.99 373.667C263.322 375.208 260.035 375.208 257.367 373.667L104.848 285.61C102.18 284.07 100.536 281.223 100.536 278.143V102.028C100.536 98.9477 102.18 96.1011 104.848 94.5608L257.367 6.50371Z"
            fill="url(#paint0_linear_4681_31607)"
          />
          <path
            d="M257.368 34.2147C260.036 32.6743 263.323 32.6743 265.99 34.2147L395.038 108.72C397.706 110.261 399.349 113.107 399.349 116.188V265.199C399.349 268.28 397.706 271.126 395.038 272.667L265.99 347.172C263.323 348.713 260.036 348.713 257.368 347.172L128.32 272.667C125.652 271.126 124.009 268.28 124.009 265.199V116.188C124.009 113.107 125.652 110.261 128.32 108.72L257.368 34.2147Z"
            fill="url(#paint1_linear_4681_31607)"
          />
          <path
            d="M3.69336 360.185V336.167L188.605 333.938V358.081C188.605 366.376 174.775 374.547 167.178 376.033H26.2345C10.7777 374.052 4.76675 364.642 3.69336 360.185Z"
            fill="#303442"
          />
          <path
            d="M107.016 306.205C129.202 299.272 143.167 310.415 147.377 316.853C155.466 314.707 178.021 310.932 186.128 323.538C192.614 333.621 186.541 340.772 178.888 345.608C173.177 349.217 166.328 350.281 159.571 350.281H24.3832C22.6832 350.281 20.9657 350.11 19.3211 349.68C-13.3049 341.146 12.1781 316.853 32.2363 316.853C28.7697 286.149 91.416 286.644 107.016 306.205Z"
            fill="#414659"
          />
          <path
            d="M97.3587 317.472L77.5496 322.919V333.072C76.9718 334.887 81.3135 339.51 97.3587 343.471C113.404 339.51 117.746 334.887 117.168 333.072V322.919L97.3587 317.472Z"
            fill="#4A5066"
          />
          <path
            d="M97.3587 314.377L77.5496 319.824V329.976C76.9718 331.792 81.3135 336.414 97.3587 340.376C113.404 336.414 117.746 331.792 117.168 329.976V319.824L97.3587 314.377Z"
            fill="#5D6480"
          />
          <path
            d="M119.025 376.157L105.654 355.605L89.1875 372.071L93.1493 376.157H119.025Z"
            fill="#252833"
          />
          <rect
            x="87.9492"
            y="354.985"
            width="18.571"
            height="17.7044"
            rx="1.98091"
            fill="#4A5066"
          />
          <rect
            x="91.54"
            y="358.576"
            width="2.84756"
            height="7.67603"
            fill="#232735"
          />
          <rect
            x="100.33"
            y="358.576"
            width="2.84756"
            height="7.67603"
            fill="#232735"
          />
          <rect
            x="95.9346"
            y="367.119"
            width="2.84756"
            height="2.47614"
            fill="#232735"
          />
          <path
            d="M223.449 405.273V379.768C223.449 378.674 224.336 377.787 225.43 377.787H276.068C276.47 377.787 276.864 377.91 277.195 378.139L290.689 387.476C291.224 387.846 291.543 388.454 291.543 389.105V405.273C291.543 406.367 290.656 407.254 289.562 407.254H225.43C224.336 407.254 223.449 406.367 223.449 405.273Z"
            fill="#303442"
          />
          <path
            d="M223.449 398.339V370.359C223.449 369.265 224.336 368.378 225.43 368.378H273.937C274.278 368.378 274.614 368.466 274.911 368.635L290.537 377.466C291.159 377.818 291.543 378.477 291.543 379.191V398.339C291.543 399.433 290.656 400.32 289.562 400.32H225.43C224.336 400.32 223.449 399.433 223.449 398.339Z"
            fill="#414659"
          />
          <rect
            x="233.602"
            y="377.787"
            width="23.8947"
            height="2.72375"
            fill="#5D6480"
          />
          <rect
            x="233.602"
            y="382.492"
            width="47.7895"
            height="2.72375"
            fill="#5D6480"
          />
          <rect
            x="233.602"
            y="387.197"
            width="47.7895"
            height="2.72375"
            fill="#5D6480"
          />
          <rect
            x="233.602"
            y="391.902"
            width="47.7895"
            height="2.72375"
            fill="#5D6480"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M373.088 249.953C372.283 249.794 371.501 250.318 371.343 251.123C371.184 251.928 371.708 252.709 372.513 252.868C382.454 254.826 404.612 271.038 406.973 321.802L406.974 321.836L406.978 321.87C407.84 331.144 415.257 337.96 423.613 342.663C432.034 347.403 441.832 350.246 448.305 351.422L448.355 351.432L448.406 351.437C452.079 351.845 456.333 352.496 460.31 353.379C464.312 354.269 467.931 355.373 470.394 356.652C471.632 357.296 472.461 357.925 472.919 358.494C473.339 359.017 473.388 359.397 473.293 359.752C473.171 360.207 472.724 360.928 471.485 361.87C470.269 362.794 468.432 363.822 465.816 364.936C460.678 367.125 452.696 368.711 443.008 369.801C433.356 370.886 422.142 371.467 410.618 371.701C387.571 372.171 363.373 371.256 348.135 370.268C348.134 370.268 348.133 370.268 348.132 370.268L348.432 367.09C348.48 366.584 348.137 366.124 347.639 366.025L334.089 363.334C333.514 363.22 332.965 363.628 332.91 364.212L332.708 366.355L327.003 365.817L326.84 367.542L332.545 368.08L332.135 372.435L326.283 371.883L326.12 373.609L331.972 374.161L331.787 376.113C331.734 376.685 332.176 377.183 332.75 377.197L346.524 377.518C347.043 377.53 347.484 377.138 347.533 376.621L347.853 373.225C347.883 373.228 347.913 373.231 347.943 373.233C363.244 374.226 387.529 375.144 410.679 374.672C422.253 374.436 433.565 373.853 443.34 372.753C453.078 371.658 461.422 370.037 466.981 367.67C469.713 366.506 471.795 365.366 473.283 364.235C474.749 363.121 475.793 361.898 476.162 360.523C476.559 359.048 476.102 357.711 475.234 356.632C474.404 355.6 473.162 354.742 471.763 354.015C468.952 352.555 465.031 351.385 460.955 350.479C456.872 349.572 452.53 348.907 448.786 348.49C442.547 347.35 433.11 344.599 425.07 340.074C416.955 335.506 410.676 329.374 409.939 321.628C407.538 270.204 384.935 252.286 373.088 249.953Z"
            fill="#414659"
          />
          <path
            d="M257.367 63.6201C260.035 62.0797 263.322 62.0797 265.99 63.6201L371.387 124.471C374.055 126.011 375.698 128.858 375.698 131.939V253.641C375.698 256.721 374.055 259.568 371.387 261.108L265.99 321.959C263.322 323.5 260.035 323.5 257.367 321.959L151.97 261.108C149.302 259.568 147.658 256.721 147.658 253.641V131.939C147.658 128.858 149.302 126.011 151.97 124.471L257.367 63.6201Z"
            fill="#303442"
          />
          <path
            d="M340.539 163.662L339.545 200.223H330.213L329.194 163.662H340.539ZM334.879 216.54C333.196 216.54 331.752 215.945 330.545 214.755C329.338 213.548 328.743 212.104 328.76 210.421C328.743 208.755 329.338 207.328 330.545 206.138C331.752 204.948 333.196 204.353 334.879 204.353C336.494 204.353 337.913 204.948 339.137 206.138C340.361 207.328 340.981 208.755 340.998 210.421C340.981 211.543 340.683 212.571 340.106 213.506C339.545 214.424 338.805 215.163 337.887 215.724C336.97 216.268 335.967 216.54 334.879 216.54Z"
            fill="white"
          />
          <path
            d="M278.011 206.699V198.005L299.809 163.662H307.305V175.696H302.869L289.127 197.444V197.852H320.104V206.699H278.011ZM303.073 215.877V204.047L303.277 200.197V163.662H313.628V215.877H303.073Z"
            fill="white"
          />
          <path
            d="M249.845 217.024C245.46 217.007 241.686 215.928 238.525 213.786C235.38 211.645 232.958 208.543 231.259 204.481C229.576 200.418 228.743 195.532 228.76 189.821C228.76 184.127 229.601 179.274 231.284 175.263C232.984 171.252 235.406 168.201 238.55 166.11C241.712 164.002 245.477 162.949 249.845 162.949C254.213 162.949 257.969 164.002 261.114 166.11C264.275 168.218 266.706 171.277 268.405 175.288C270.105 179.283 270.946 184.127 270.929 189.821C270.929 195.549 270.08 200.444 268.38 204.506C266.697 208.568 264.284 211.67 261.139 213.812C257.995 215.954 254.23 217.024 249.845 217.024ZM249.845 207.871C252.836 207.871 255.224 206.367 257.009 203.359C258.794 200.35 259.677 195.838 259.66 189.821C259.66 185.86 259.253 182.563 258.437 179.928C257.638 177.294 256.499 175.314 255.02 173.988C253.559 172.662 251.833 171.999 249.845 171.999C246.87 171.999 244.491 173.487 242.706 176.461C240.921 179.436 240.021 183.889 240.004 189.821C240.004 193.832 240.403 197.18 241.202 199.866C242.018 202.534 243.165 204.54 244.644 205.883C246.122 207.209 247.856 207.871 249.845 207.871Z"
            fill="white"
          />
          <path
            d="M179.924 206.699V198.005L201.722 163.662H209.218V175.696H204.782L191.04 197.444V197.852H222.017V206.699H179.924ZM204.986 215.877V204.047L205.19 200.197V163.662H215.541V215.877H204.986Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4681_31607"
              x1="261.678"
              y1="-89.1101"
              x2="261.678"
              y2="332.824"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3E4353" stopOpacity="0.51" />
              <stop offset={1} stopColor="#8A95B9" stopOpacity={0} />
            </linearGradient>
            <linearGradient
              id="paint1_linear_4681_31607"
              x1="261.679"
              y1="-14.8088"
              x2="261.679"
              y2="349.662"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3E4353" stopOpacity="0.7" />
              <stop offset="0.762727" stopColor="#8A95B9" stopOpacity="0.04" />
            </linearGradient>
          </defs>
        </svg>
        <p className="text-gray-500 mt-4 pb-4 border-b-2 text-center">
          Sorry, the page you are looking for could not be found.
        </p>
        <a
          href="/"
          className="flex items-center space-x-2 bg-blue-600 hover:bg-blue-700 text-gray-100 px-4 py-2 mt-6 rounded transition duration-150"
          title="Return Home"
        >
          <span>Return Home</span>
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
