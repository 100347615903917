import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CardItems from "../../components/Namespaces/CardItems";
import { RootState } from "../../store/store";
import { getListAsync } from "./../../store/ManageNamespace";
import { changeTotal, resetState } from "./../../store/pagingSlice";

import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { changeVisibleSpinner } from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import EmptySvg from "./../../assets/nodata.svg";

const dataBread = [
  {
    key: 0,
    value: "Namespace",
  },
];
const ListNamespacePage = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { page, limit } = useSelector((state: RootState) => state.paging);
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);

  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        setLoading(true);
        // @ts-ignore

        const res = await dispatch(
          // @ts-ignore
          getListAsync({
            page_size: 10000,
          })
        ).unwrap();
        dispatch(changeTotal(res?.namespaces?.length));

        setData(
          res?.namespaces?.filter(
            (item: any, index: any) =>
              page * limit <= index && index < (page + 1) * limit
          ) ?? []
        );

        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      }
    })();
  }, [page]);

  useEffect(() => {
    dispatch(changePath("/namespaces"));
    dispatch(changeName("namespaces"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      dispatch(resetState());
    };
  }, []);
  return (
    <div className="px-6 py-6 !w-full div-list !relative">
      {loading && <LoadingListComp />}

      {visibleSpinner && !loading && <LoadingGlobalComp />}

      {!data?.length && data?.length === 0 && !loading && (
        <center>
          <img src={EmptySvg} alt="empty" />
          <p className="mt-3 text-neutral-50">No data</p>
        </center>
      )}

      {data?.length !== 0 && <CardItems data={data} />}
    </div>
  );
};

export default ListNamespacePage;
