import { Baselayout as BaseLayout } from "@web/reactjs-layout";
import { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";

import { useDispatch, useSelector } from "react-redux";
import AlertSvg from "./assets/Alert.svg";
import DatasetSvg from "./assets/dataset-icon-menu.svg";
import DropdownSvg from "./assets/dropdown.svg";
import MenuButtonSvg from "./assets/menu_button.svg";

import SearchBarComp from "./components/Searchbar/index";
import UserInforButton from "./components/UserInfor/index";
import Alert from "./components/message/Alert";
import AddIdentitiesPage from "./pages/Identities/Add";
import EditIdentitiesPage from "./pages/Identities/Edit";
import ListIdentitesPage from "./pages/Identities/List";
import ChangePwdPage from './pages/Identities/ChangePwd'
import ViewIdentitiesPage from "./pages/Identities/[identitiesId]";
import IdentitesPage from "./pages/Identities/index";
import ListNameSpacePage from "./pages/Namespaces/List";
import ListRelationshipPage from "./pages/Relationship/List";

// import AddRelationshipPage from "./pages/Relationship/Add";
import AddRelationshipPage from "./components/Relationship/FormAdd";
import EditRelationshipPage from "./components/Relationship/FormEdit";
import InsertRelationshipPage from "./components/Relationship/FormInsert";
import FormNewInsertPage from "./components/Relationship/FormNewInsert";
import RelationshipPage from "./pages/Relationship/index";
import ListSchemaPage from "./pages/Schemas/List";
import ViewDetailSchemaPage from "./pages/Schemas/[schemaId]";
import Page403 from "./pages/errors/403";
import Page404 from "./pages/errors/404";
import HomePage from "./pages/home";

import { Spinner, Toaster } from "@fluentui/react-components";

import { getListAsync } from "./store/ManageNamespace";
import { getInforAsync } from "./store/ManageUserSlice";
import { getMyRolesAsync } from "./store/roleSlice";
import { RootState } from "./store/store";

import ActionToolNavComp from "./components/ActionToolNav";
import Footer from "./components/Footer";
import { horizontal, vertical } from "./utils/constants";
import axios from './utils/axios';
import { loadConfig } from "./config";
import GlobalDrawer from "./components/Drawer/Drawer";

import { getListAsync as getListRelationship } from "./store/ManageRelationship";

axios.interceptors.response.use(
  function (response) {
    return response;
  },

  async function (error) {
    const kratosConfig = await loadConfig(process.env.REACT_APP_CFG_URL);
    if (
      error?.response?.status === 401 ||
      error?.code === 401 ||
      error?.response?.data?.error?.code === 401
    ) {
      console.log(error);

      // @ts-ignore
      // window.location.replace(kratosConfig?.ipAccount);
    }
    return error;
  }
);

function App() {
  const dispatch = useDispatch();
  const { inforUser } = useSelector((state: RootState) => state.manageuser);
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  let showAlert: boolean = useSelector(
    (state: RootState) => state.notifications?.alert?.show
  );
  const [loading, setLoading] = useState<boolean>(true);

  const [menus, setMenus] = useState([
    {
      title: "Identities",
      path: "/identities",
      icon: <img className="img-svg-icon" src={DatasetSvg} alt="Identities" />,
    },
    {
      title: "Relationship",
      path: "/relationship",
      icon: (
        <img className="img-svg-icon" src={DatasetSvg} alt="Relationship" />
      ),
      menuicon: (
        <img
          className="img-svg-icon-menu"
          src={DropdownSvg}
          alt="manage-storage"
        />
      ),
      children: [],
    },
    {
      title: "Namespaces",
      path: "/namespaces",
      icon: <img className="img-svg-icon" src={DatasetSvg} alt="Namespaces" />,
    },
    {
      title: "Schemas",
      path: "/schemas",
      icon: <img className="img-svg-icon" src={DatasetSvg} alt="schemas" />,
    },
  ]);

  const search = (val: any) => {
    //handle call api here
  };
  const navs = [
    {
      title: "Web Admin",
      icon: <img src={MenuButtonSvg} alt="menu_button" />,
    },

    {
      title: "Alert",
      icon: <img src={AlertSvg} alt="Alert" />,
    },
  ];

  const getDataConfig = async () => {
    const data = await loadConfig(process.env.REACT_APP_CFG_URL);

    localStorage.setItem("kratosAdminURL", data?.kratosAdminURL);
    localStorage.setItem("kratosPublicURL", data?.kratosPublicURL);
    localStorage.setItem("reverseProxy", data?.reverseProxy);
    localStorage.setItem("ip", data?.ip);
    localStorage.setItem("REACT_APP_BASE_MAP", data?.REACT_APP_BASE_MAP);
    localStorage.setItem("ketoAdminUrl", data?.ketoAdminUrl);
    localStorage.setItem("ketoPublicUrl", data?.ketoPublicUrl);
    localStorage.setItem("ipAccount", data?.ipAccount);
  };

  useEffect(() => {
    getDataConfig();
    getMyInfo();
  }, []);

  const getNamespaces = async () => {
    try {
      const res = await dispatch(
        // @ts-ignore
        getListAsync()
      ).unwrap();
      if (res && Object.keys(res).length) {
        createMenus(res?.namespaces ?? []);
      }
    } catch (e) {}
  };

  const getMyInfo = async () => {
    setLoading(true);
    try {
      // const res = await dispatch(
      //   // @ts-ignore

      //   getMyRolesAsync()
      // ).unwrap();
      // @ts-ignore
      dispatch(getInforAsync());
      await getNamespaces();
      await getObjectRelationship();

      // if (
      //   res &&
      //   Object.keys(res) &&
      //   (res?.includes("admin") || res?.includes("Admin"))
      // ) {
      //   // @ts-ignore
      //   dispatch(getInforAsync());
      //   await getNamespaces();
      //   await getObjectRelationship();
      // } else {
      //   //  window.location.replace("https://test-account.geohub.ai/");
      // }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const createMenus = (namespaces: Array<any>) => {
    let menusCopy = menus;
    let index = menusCopy.findIndex((x) => x.path === "/relationship");

    if (index >= 0) {
      let relationshipMenus: Array<any> = [];
      namespaces.forEach((n) => {
        relationshipMenus = [
          ...relationshipMenus,
          {
            title: n?.name,
            path: "/relationship/" + n?.name,
            icon: (
              <img className="img-svg-icon" src={DatasetSvg} alt="Namespaces" />
            ),
          },
        ];
      });

      //@ts-ignore
      menusCopy[index].children = relationshipMenus;

      setMenus(menusCopy);
    }
  };

  const { visibleFilter, visibleSort, visibleRecovery } = useSelector(
    (state: RootState) => state.drawer
  );

  let pageName: string | undefined = useSelector(
    (state: RootState) => state.page?.name
  );

  const getObjectRelationship = async () => {
    let a: any = [];
    //@ts-ignore
    const res = await dispatch(getListRelationship()).unwrap();

    if (res?.relation_tuples?.length > 0) {
      for (let i = 0; i < res?.relation_tuples?.length; i++) {
        if (
          res?.relation_tuples?.[i]?.object &&
          !a?.includes(res?.relation_tuples?.[i]?.object)
        ) {
          a.push(res?.relation_tuples?.[i]?.object);
        }
      }
    }

    let b: any = localStorage.getItem("arrObject");
    let c = b.split(",") ?? [];

    if (b && b !== "") {
      const mergedArray = c?.concat(a);
      let uniqueArr = mergedArray.filter(
        (item: any, index: any) => mergedArray.indexOf(item) === index
      );
      localStorage.setItem("arrObject", uniqueArr);
    } else {
      localStorage.setItem("arrObject", a);
    }
  };

  return (
    <>
      {showAlert && <Alert />}
      {loading ? (
        <center>
          <Spinner className="absolute top-1/2 left-1/2 z-9999" />
        </center>
      ) : (
        <Router>
          <BaseLayout
            handleSearch={search}
            dataMenu={menus}
            dataNav={navs}
            componentSearch={<SearchBarComp />}
            componentFooter={<Footer />}
            componentUserInfor={<UserInforButton inforUser={inforUser} />}
            componentButtonCreate={<ActionToolNavComp />} // component has many action button in nav
          >
            <Toaster toasterId={toasterId} offset={{ horizontal, vertical }} />

            <Routes>
              <Route index path="/" element={<HomePage />} />

              {/* identities */}
              <Route path="/identities" element={<IdentitesPage />}>
                <Route path="" element={<ListIdentitesPage />} />
                <Route path="/identities/add" element={<AddIdentitiesPage />} />
                <Route
                  path="/identities/edit/:id"
                  element={<EditIdentitiesPage />}
                />
                <Route path=":id/change-pwd" element={<ChangePwdPage />} />
                <Route
                  path="/identities/:id"
                  element={<ViewIdentitiesPage />}
                />
              </Route>
              {/* end identities */}

              {/* relationship */}
              <Route path="/relationship" element={<RelationshipPage />}>
                <Route path=":id" element={<ListRelationshipPage />} />
                <Route
                  path=":id/add"
                  element={<AddRelationshipPage type="add" />}
                />
                <Route path=":id/edit" element={<EditRelationshipPage />} />

                <Route path=":id/insert" element={<InsertRelationshipPage />} />
                <Route path=":id/new-insert" element={<FormNewInsertPage />} />
              </Route>
              {/* end relationship */}

              <Route path="/schemas" element={<ListSchemaPage />} />
              <Route path="/schemas/:id" element={<ViewDetailSchemaPage />} />

              {/* namespaces */}
              <Route path="/namespaces" element={<ListNameSpacePage />} />
              {/* end namespaces */}

              <Route path="/403" element={<Page403 />} />
              <Route path="*" element={<Page404 />} />
            </Routes>

            {(visibleFilter || visibleSort || visibleRecovery) && (
              <GlobalDrawer pageName={pageName} />
            )}
          </BaseLayout>
        </Router>
      )}
    </>
  );
}

export default App;
