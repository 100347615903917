/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { RootState } from "../../store/store";
import
  {
    changeArrPathNextPage,
    changeFlagFilter,
    changeFlagRefresh,
    changeFlagRemove,
    changeFlagSearchBar,
    changeKeyword,
    deleteMultipleRelationshipAsync,
    deleteRelationshipAsync,
    getListAsync,
    resertFilterState,
    resetAllStateRelationship,
  } from "./../../store/ManageRelationship";
import
  {
    changeNextPageToken,
    resetNextPage,
    resetState
  } from "./../../store/pagingSlice";

import CardItems from "../../components/Relationship/CardItems";

import EmptySvg from "./../../assets/nodata.svg";

import
  {
    Checkbox,
    Toast,
    ToastBody,
    ToastTitle,
    useToastController,
  } from "@fluentui/react-components";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import
  {
    changeStateBtnCreate,
    changeVisibleSpinner,
    changeVisibleToolAction,
  } from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
const timeout = 3000;

export default function ListRelationshipPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  const {
    flagRefresh,
    arrPathNextPage,
    flagRemove,
    flagFilter,
    keyword,
    flagSearchBar,
  } = useSelector((state: RootState) => state.managerelationship);
  const { page, limit, indPathNext, flagPage } = useSelector(
    (state: RootState) => state.paging
  );

  const {
    subject_id,
    subject_namespace,
    subject_object,
    subject_relation,
    namespace,
    object,
    relation,
  } = useSelector((state: RootState) => state.managerelationship);
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );
  const { dispatchToast } = useToastController(toasterId);

  const [arrSelected, setArrSelected] = useState<any>([]);

  const [selectedSubId, setSelectedSubId] = useState<any>(null);
  const [selectedRelation, setSelectedRelation] = useState<any>(null);
  const [selectedObject, setSelectedObject] = useState<string | any>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const [relationships, setRelationships] = useState<Array<any>>([]);

  const [identities, setIdentities] = useState<Array<any>>([]);

  useEffect(() => {
    dispatch(changePath("/relationship/:id"));
    dispatch(changeName("relationship/:id"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  }, []);

  useEffect(() => {
    dispatch(
      changeStateBtnCreate({
        flag: true,
        path: `/relationship/${id}/add`,
      })
    );
    return () => {
      dispatch(changeStateBtnCreate({ flag: false, path: "" }));
      // dispatch(changeVisibleToolAction(false));
    };
  }, []);

  useEffect(() =>
  {
    dispatch(resetState());
    searchRelationShips({});
    // @ts-ignore
    dispatch(resetNextPage({}));
    dispatch(
      changeDataBreadcrumb([
        {
          key: 0,
          value: `Relationship ${id}`,
        },
      ])
    );
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState());
      
      dispatch(resetAllStateDrawer({}));
      if (!window.location.pathname?.includes(`relationship/${id}`))
      {
        dispatch(resetAllStateRelationship({}));
        dispatch(changeKeyword(""));
        // @ts-ignore
        dispatch(resetNextPage({}));
      }
        
    };
  }, [id]);

  useEffect(() => {
    (async function ()
    {
     
      try {
        dispatch(changeVisibleSpinner(true));
        setArrSelected([]);
        dispatch(changeVisibleToolAction(false));

        searchRelationShips({
          object: selectedObject,
          relation: selectedRelation,
          subject_id: selectedSubId,
        });

        dispatch(changeVisibleSpinner(false));
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
      }
    })();
  }, [indPathNext]);

  useEffect(() => {
    if (page > 0)
    {
      console.log("page");
      searchRelationShips({
        object: object,
        relation: relation,
        subject_id: subject_id && subject_id !== "" ? subject_id : null,
        "subject_set.namespace":
          subject_namespace && subject_namespace !== ""
            ? subject_namespace
            : null,
        "subject_set.object": subject_object,
        "subject_set.relation": subject_relation,
      });
    } else {
      dispatch(changeNextPageToken(null));
      if (!loading) searchRelationShips({});
    }
  }, [page]);

  useEffect(() => {
    if (flagRefresh)
      (async function ()
      {
        console.log("flagRefresh");
        try {
          await refresh();
          dispatch(changeFlagRefresh(false));
          dispatch(resertFilterState({}));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagRefresh(false));
          setLoading(false);
        }
      })();
  }, [flagRefresh]);

  useEffect(() => {
    if (flagFilter)
      (async function ()
      {
          console.log("flagFilter");
        try {
          await searchRelationShips({
            object: object,
            relation: relation,
            subject_id: subject_id && subject_id !== "" ? subject_id : null,
            "subject_set.namespace":
              subject_namespace && subject_namespace !== ""
                ? subject_namespace
                : null,
            "subject_set.object": subject_object,
            "subject_set.relation": subject_relation,
          });
          dispatch(changeFlagFilter(false));
          // dispatch(changeKeyword(""));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagFilter(false));
          dispatch(changeKeyword(""));
          setLoading(false);
        }
      })();
  }, [flagFilter]);

  useEffect(() => {
    if (flagRemove)
      (async function ()
      {
        console.log("flagRemove");
        if (arrSelected?.length === 1) {
          try {
            await deleteRelationship(arrSelected?.[0]);
            dispatch(changeFlagRemove(false));
          } catch (e) {
            console.error(e);
            dispatch(changeVisibleSpinner(false));
            dispatch(changeFlagRemove(false));
            setLoading(false);
          }
        } else {
          try {
            await deleteMultipleRelationship();
            dispatch(changeFlagRemove(false));
          } catch (e) {
            console.error(e);
            dispatch(changeVisibleSpinner(false));
            dispatch(changeFlagRemove(false));
            setLoading(false);
          }
        }
      })();
  }, [flagRemove]);

  useEffect(() => {
    if (flagSearchBar)
      (async function ()
      {
        console.log("flagSearchBar");
        try {
          await handleSearchByKeyword();
          dispatch(changeFlagSearchBar(false));
          // dispatch(changeKeyword(""));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagSearchBar(false));
          dispatch(changeKeyword(""));
          setLoading(false);
        }
      })();
  }, [flagSearchBar]);

  const handleSearchByKeyword = async () => {
    if (keyword && keyword !== "") {
      let a: any = [];
      const resObject = await dispatch(
        //@ts-ignore
        getListAsync({
          object: keyword,
          relation: relation,
          subject_id: subject_id && subject_id !== "" ? subject_id : null,
          "subject_set.namespace":
            subject_namespace && subject_namespace !== ""
              ? subject_namespace
              : null,
          "subject_set.object": subject_object,
          "subject_set.relation": subject_relation,
        })
      ).unwrap();

      a = a.concat(resObject?.relation_tuples);

      const resRelation = await dispatch(
        //@ts-ignore
        getListAsync({
          relation: keyword,
          object: object,
          subject_id: subject_id && subject_id !== "" ? subject_id : null,
          "subject_set.namespace":
            subject_namespace && subject_namespace !== ""
              ? subject_namespace
              : null,
          "subject_set.object": subject_object,
          "subject_set.relation": subject_relation,
        })
      ).unwrap();

      a = a.concat(resRelation?.relation_tuples);

      const resSubjectId = await dispatch(
        //@ts-ignore
        getListAsync({
          subject_id: keyword,

          object: object,
          relation: relation,
          "subject_set.namespace":
            subject_namespace && subject_namespace !== ""
              ? subject_namespace
              : null,
          "subject_set.object": subject_object,
          "subject_set.relation": subject_relation,
        })
      ).unwrap();

      a = a.concat(resSubjectId?.relation_tuples);

      const res = a?.filter(
        (item: any, index: any) => a.indexOf(item) === index
      );

      setRelationships(res ?? []);
    } else {
      searchRelationShips({
        // page_token: flagRefresh ? "" : arrPathNextPage?.[indPathNext],
        // page_size: limit ?? 25,
        namespace: id,
        object: object,
        relation: relation,
        subject_id: subject_id && subject_id !== "" ? subject_id : null,
        "subject_set.namespace":
          subject_namespace && subject_namespace !== ""
            ? subject_namespace
            : null,
        "subject_set.object": subject_object,
        "subject_set.relation": subject_relation,
      });
    }
  };

  const searchRelationShips = async (params: any) => {
    let payload = {
      page_token: flagRefresh ? "" : arrPathNextPage?.[indPathNext],
      page_size: limit ?? 25,
      namespace: id,
      object: params?.object,
      relation: params?.relation,
      subject_id:
        params?.subject_id && params?.subject_id !== ""
          ? params?.subject_id
          : null,
      "subject_set.namespace":
        params?.["subject_set.namespace"] &&
        params?.["subject_set.namespace"] !== ""
          ? params?.["subject_set.namespace"]
          : null,
      "subject_set.object": params?.["subject_set.object"],
      "subject_set.relation": params?.["subject_set.relation"],
    };

    setSelectedObject(params?.object ?? null);
    setSelectedSubId(params?.subject_id ?? null);
    setSelectedRelation(params?.relation ?? null);

    onSearch(payload);
  };

  const onSearch = async (payload: any) => {
    setLoading(true);
    try {
      //@ts-ignore
      const res = await dispatch(getListAsync(payload)).unwrap();
      if (res && Object.keys(res).length) {
        // dispatch(changeNextPageToken(res?.next_page_token));
        setRelationships(res?.relation_tuples ?? []);
      }

      if (flagPage === "next") {
        // neu click btn next thi moi can save lai path o next
        let sun = [...arrPathNextPage];

        let tun = res?.next_page_token;

        if (!sun?.includes(tun)) {
          sun.push(tun);
        }

        console.log(sun);

        dispatch(changeArrPathNextPage(sun));
      }
    } catch (e) {}

    setLoading(false);
  };

  const notify = (result: any, type: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>Notification</ToastTitle>
        <ToastBody>{result}</ToastBody>
      </Toast>,
      { timeout, intent: type }
    );
  };

  const editRelationship = (item: any) => {
    navigate(`edit`, {
      state: { itemEdit: item },
    });
  };
  const deleteRelationship = async (item: any) => {
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    const res = await dispatch(deleteRelationshipAsync(item)).unwrap();
    if (res?.status === 204) {
      if (flagRemove) {
        setArrSelected([]);
        dispatch(changeVisibleToolAction(false));
      }
      setTimeout(() => {
        notify(`Delete relationship successfully`, "success");
        dispatch(changeVisibleSpinner(false));
        dispatch(changeFlagRefresh(true));
      }, 1500);
    } else {
      setTimeout(() => {
        notify(`Delete relationship fail`, "error");
        dispatch(changeVisibleSpinner(false));
      }, 1500);
    }
  };

  const refresh = async () => {
    dispatch(changeArrPathNextPage([""]));
    // @ts-ignore
    dispatch(resetNextPage({}));
    dispatch(changeKeyword(""));
    await searchRelationShips({});
  };

  const pickItem = (item: any) => {
    let items = arrSelected.slice(); // copy
    let index = items.findIndex(
      (x: any) =>
        x?.subject_id === item?.subject_id &&
        x?.relation === item?.relation &&
        x?.object === item?.object &&
        x?.namespace === item?.namespace &&
        x?.subject_set?.namespace === item?.subject_set?.namespace &&
        x?.subject_set?.object === item?.subject_set?.object &&
        x?.subject_set?.relation === item?.subject_set?.relation
    );
    if (index < 0) {
      items.push(item);
    } else {
      items.splice(index, 1);
    }
    setArrSelected(items);

    if (items?.length > 0) {
      dispatch(changeVisibleToolAction(true));
    } else {
      dispatch(changeVisibleToolAction(false));
    }
  };

  const deleteMultipleRelationship = async () => {
    dispatch(changeVisibleSpinner(true));
    let objectCopy = arrSelected;
    let arrRelationTuple: any = [];
    for (let i = 0; i < objectCopy?.length; i++) {
      arrRelationTuple?.push({
        action: "delete",
        relation_tuple: objectCopy?.[i],
      });
    }
    const res = await dispatch(
      // @ts-ignore
      deleteMultipleRelationshipAsync({ arrRelationTuple })
    ).unwrap();
    if (res?.status === 204) {
      setArrSelected([]);
      dispatch(changeVisibleToolAction(false));

      setTimeout(() => {
        notify(
          `Delete ${arrRelationTuple?.length} relationships successfully`,
          "success"
        );
        dispatch(changeVisibleSpinner(false));
        dispatch(changeFlagRefresh(true));
        // refresh();
      }, 1500);
    } else {
      setTimeout(() => {
        notify(`Delete relationship fail`, "error");
        dispatch(changeVisibleSpinner(false));
      }, 1500);
    }
  };

  console.log(keyword, 'keyword')

  return (
    <>
      <div className="px-6 py-6 !w-full div-list !relative">
        {loading && <LoadingListComp />}

        {visibleSpinner && !loading && <LoadingGlobalComp />}

        {relationships?.length === 0 && !loading && (
          <center>
            <img src={EmptySvg} alt="empty" />
            <p className="mt-3 text-neutral-50">No data</p>
          </center>
        )}

        {relationships?.length !== 0 && !loading && (
          <>
            <div className="flex gap-1 mb-2">
              <Checkbox
                checked={
                  arrSelected?.length === relationships?.length
                    ? true
                    : arrSelected?.length === 0
                    ? false
                    : "mixed"
                }
                onChange={(_ev, val) => {
                  if (val?.checked) {
                    setArrSelected(relationships);
                    dispatch(changeVisibleToolAction(true));
                  } else {
                    setArrSelected([]);
                    dispatch(changeVisibleToolAction(false));
                  }
                }}
                label="Select all in this page"
              />
            </div>
            <div style={{ marginTop: "5px" }}>
              <CardItems
                data={relationships}
                // identities={identities}
                editRelationship={(item: any) => editRelationship(item)}
                deleteRelationship={(item: any) => deleteRelationship(item)}
                arrSelected={arrSelected}
                pickItem={pickItem}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
