import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getMyRoles
} from "./../services/ManageUser/ManageUser";

export interface SerializedError {
    name?: string
    message?: string
    stack?: string
    code?: string
}

var error: SerializedError = {};
export interface RoleState {
    myRoles: Array<string>,

    loading: string,
    currentRequestId: any,
    error: SerializedError
}


const initialState: RoleState = {
    myRoles: [],
    loading: 'idle',
    currentRequestId: undefined,
    error: error
};



export const getMyRolesAsync = createAsyncThunk(
    "getMyRoles",
    async () => {
        try {
            const response = await getMyRoles();
            return response?.data;
        } catch (e) {
            return [];
        }
    }
);


export const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMyRolesAsync.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.currentRequestId = action?.meta?.requestId;
                    state.loading = 'pending';
                }
            })
            .addCase(getMyRolesAsync.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.currentRequestId = undefined;
                    state.myRoles = action?.payload ?? []
                }
            })
            .addCase(getMyRolesAsync.rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
    }
});

export const { } = roleSlice.actions

export default roleSlice.reducer