import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Menu,
  Checkbox,
  Tooltip,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import SuccessIcon from "../../assets/dataset.svg";
import NextSvg from "../../assets/next.svg";
import DeleteSvg from "./../../assets/delete-dark.svg";
import EditSvg from "./../../assets/edit-dark.svg";

export default function CardItems(props: any) {
  const { t } = useTranslation();

  return (
    <>
      {props?.data?.map((item: any, index: number) => (
        <section key={index}>
          <div className="bg-default rounded">
            <Card
              orientation="horizontal"
              className="card-item-storage bg-default !h-[100px]"
            >
              <CardPreview className="div-center div-card-preview !flex-row mr-2">
                <div className="m-3">
                  <Checkbox
                    onChange={(e: any, data: any) => {
                      props?.pickItem(item);
                    }}
                    checked={props?.arrSelected?.includes(item)}
                  />
                </div>
                <img
                  className="img-card-item-catalog"
                  src={SuccessIcon}
                  alt="img item"
                />
              </CardPreview>
              <CardHeader
                className="w-auto div-card-header-with-checkbox"
                header={
                  <div className="divHeader relative">
                    <div className="block w-full">
                      <p>
                        <span className="text-color-des">Object:</span>{" "}
                        {item?.object}
                      </p>
                      <p>
                        <span className="text-color-des">Relation:</span>{" "}
                        {item?.relation}
                      </p>
                      {item?.subject_id && (
                        <p>
                          <span className="text-color-des">Subject_id:</span>{" "}
                          {item?.subject_id}
                        </p>
                      )}

                      {item?.subject_set?.object && (
                        <>
                          <Tooltip
                            content="Subject set namespace - relation - object"
                            relationship="label"
                          >
                            <p>
                              <span className="text-color-des">
                                Subject_set:
                              </span>{" "}
                              {item?.subject_set?.namespace}{" "}
                              {item?.subject_set?.relation}{" "}
                              {item?.subject_set?.object}
                            </p>
                          </Tooltip>

                          {/* <p>
                            <span className="text-color-des">
                              Subject_set.object:
                            </span>{" "}
                            {item?.subject_set?.object}
                          </p>
                          <p>
                            <span className="text-color-des">
                              Subject_set.relation:
                            </span>{" "}
                            {item?.subject_set?.object}
                          </p> */}
                        </>
                      )}

                      <p className="mt-1 text-des">{item?.traits?.email}</p>
                    </div>
                  </div>
                }
              />

              <CardFooter className="footer-card-action">
                <div className="div-action-card">
                  <div
                    className="mr-3"
                    onClick={() => props?.editRelationship(item)}
                  >
                    <img
                      className="mr-2 img-svg-icon-small"
                      src={EditSvg}
                      alt="Dismiss"
                    />
                  </div>

                  <div>
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <img
                          className="mr-2 img-svg-icon-small"
                          src={DeleteSvg}
                          alt="Dismiss"
                        />
                      </MenuTrigger>

                      <MenuPopover>
                        <MenuList
                          onClick={() => {
                            props?.deleteRelationship(item);
                          }}
                        >
                          <MenuItem>Delete </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </div>
                </div>
                <div onClick={() => props?.editRelationship(item)}>
                  <img
                    className="img-svg-icon-small"
                    src={NextSvg}
                    alt="Info Circle"
                  />
                </div>
              </CardFooter>
            </Card>
          </div>
        </section>
      ))}
    </>
  );
}
