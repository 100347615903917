import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Checkbox,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
} from "@fluentui/react-components";

import { useNavigate } from "react-router-dom";
import DeleteSvg from "../../assets/delete-dark.svg";
import EditSvg from "./../../assets/edit-dark.svg";
import NextSvg from "./../../assets/next.svg";
import SuccessIcon from "./../../assets/user-active.svg";
import ErrorIcon from "./../../assets/user-inactive.svg";
import ChangePwdIcon from '../../assets/change-pwd.svg'

const CardItemIdentities = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      {props?.data?.map((item: any) => (
        <section key={item?.id}>
          <div className="bg-default rounded">
            <Card
              orientation="horizontal"
              className="card-item-storage bg-default"
            >
              <CardPreview className="div-center div-card-preview !flex-row mr-2">
                <div className="m-3">
                  <Checkbox
                    onChange={(e: any, data: any) => {
                      props?.pickItem(item);
                    }}
                    checked={props?.arrIdsDel?.includes(item?.id)}
                  />
                </div>
                {item?.state && item?.state === "active" ? (
                  <Tooltip relationship="description" content={"active"}>
                    <img
                      className="img-card-item-catalog"
                      src={SuccessIcon}
                      alt="img item"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip relationship="description" content={"inactive"}>
                    <img
                      className="img-card-item-catalog"
                      src={ErrorIcon}
                      alt="img item"
                    />
                  </Tooltip>
                )}
              </CardPreview>
              <CardHeader
                onClick={async () => {
                  navigate(`/identities/${item?.id}`, {
                    state: { itemEdit: item },
                  });
                }}
                className="w-auto div-card-header-with-checkbox"
                header={
                  <div className="divHeader relative">
                    <div className="block w-full">
                      <p className="!uppercase text-title-card ellipsis-text-one-line">
                        {item?.traits?.name?.first} {item?.traits?.name?.last}
                      </p>
                      <p className="mt-1 text-des">{item?.traits?.email}</p>
                    </div>
                  </div>
                }
              />

              <CardFooter className="footer-card-action">
                <div className="div-action-card">
                  <div
                    className="mr-3"
                    onClick={async () => {
                      navigate(`edit/${item?.id}`, {
                        state: { itemEdit: item },
                      });
                    }}
                  >
                    <img
                      className="mr-2 img-svg-icon-small"
                      src={EditSvg}
                      alt="Dismiss"
                    />
                  </div>

                  <div
                    className="mr-3"
                    onClick={async () => {
                      navigate(`/identities/${item?.id}/change-pwd`, {
                        state: { itemEdit: item },
                      });
                    }}
                  >
                    <img
                      className="mr-2 img-svg-icon-small"
                      src={ChangePwdIcon}
                      alt="ChangePwdIcon"
                    />
                  </div>

                  <div>
                    <Menu>
                      <MenuTrigger disableButtonEnhancement>
                        <img
                          className="mr-2 img-svg-icon-small"
                          src={DeleteSvg}
                          alt="Dismiss"
                        />
                      </MenuTrigger>

                      <MenuPopover>
                        <MenuList
                          onClick={() => {
                            props?.deleteUser(item?.id);
                          }}
                        >
                          <MenuItem>Delete </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  </div>
                </div>
                <div
                  onClick={async () => {
                    navigate(`/identities/${item?.id}`, {
                      state: { itemEdit: item },
                    });
                  }}
                >
                  <img
                    className="img-svg-icon-small"
                    src={NextSvg}
                    alt="Info Circle"
                  />
                </div>
              </CardFooter>
            </Card>
          </div>
        </section>
      ))}
    </>
  );
};

export default CardItemIdentities;
