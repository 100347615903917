import { createSlice } from '@reduxjs/toolkit'
import { useId } from "@fluentui/react-components";
export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        toasterId: new Date().getTime() + "",
        alert: {
            show: false,
            title: null,
            message: null,
            buttons: {
                close: false,
                yes: false,
                no: false
            }
        }
    },
    reducers: {
        changeToasterId: (state, action) => {
            state.toasterId = action?.payload;
        },
        updateAlert: (state, action) => {
            state.alert = action?.payload;
        },
    }
});

export const { changeToasterId, updateAlert } = notificationsSlice.actions

export default notificationsSlice.reducer