// xuanvt add
import {
  AvatarGroup,
  AvatarGroupItem,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

import downSvg from "./../../assets/down-circle-userinfor.svg";

const UserInforButton = (props: any) => {
  let tun =
    props?.inforUser?.data?.traits?.name?.first +
    " " +
    props?.inforUser?.data?.traits?.name?.last;

  // const { total_cart } = useSelector((state: RootState) => state.manageorder);
  return (
    <Menu>
      <MenuTrigger>
        <AvatarGroup size={24} className="relative">
          <AvatarGroupItem
            // size="32"
            active="active"
            activeAppearance="ring"
            color="red"
            name={tun}
            key="sun"
            // style={{ marginLeft: total_cart !== 0 ? "4px" : "0px" }}
          />
          <img src={downSvg} className="absolute top-4 left-4" />
        </AvatarGroup>
      </MenuTrigger>

      <MenuPopover className="menu-popover">
        <MenuList>
          <MenuItem style={{ cursor: "none" }}>
            <div className="flex">
              {props?.inforUser?.data?.traits?.name?.first +
                " " +
                props?.inforUser?.data?.traits?.name?.last}
            </div>
          </MenuItem>
          <Divider />
          <MenuItem>
            <div className="flex">Setting</div>
          </MenuItem>
          <Divider />
          <MenuItem>
            <div className="flex">Logout</div>
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default UserInforButton;
