import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Text,
} from "@fluentui/react-components";
import DatasetIconSvg from "../../assets/dataset.svg";
import NextSvg from "../../assets/next.svg";
import { useNavigate } from "react-router-dom";

const CardItemSchema = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      {props?.data?.map((item: any, index: number) => (
        <section key={index}>
          <div className="bg-default rounded">
            <Card
              orientation="horizontal"
              className="card-item-storage bg-default"
              onClick={async () => {
                navigate(`./${item?.id}`, {
                  state: { itemEdit: item },
                });
              }}
            >
              <CardPreview className="div-center div-card-preview !flex-row mr-2">
                <img
                  className="img-card-item-catalog"
                  src={DatasetIconSvg}
                  alt="img item"
                />
              </CardPreview>
              <CardHeader
                className="w-auto div-card-header"
                header={
                  <div>
                    <div>
                      <Text
                        weight="semibold"
                        style={{ overflowWrap: "anywhere" }}
                      >
                        {item?.id}
                      </Text>
                    </div>
                  </div>
                }
              />

              <CardFooter className="footer-card-action">
                <div
                  onClick={async () => {
                    navigate(`./${item?.id}`, {
                      state: { itemEdit: item },
                    });
                  }}
                >
                  <img
                    className="img-svg-icon-small"
                    src={NextSvg}
                    alt="Info Circle"
                  />
                </div>
              </CardFooter>
            </Card>
          </div>
        </section>
      ))}
    </>
  );
};

export default CardItemSchema;
