import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getList,
  addMultipleRelationship,
  addRelationship,
  delRelationship,
  deleteMultipleRelationship,
} from "./../services/ManageRelationship/ManageRelationship";

export interface ManageRelationshipState {
  page: number;
  limit: number;
  total: number;
  status: string;
  arrPathNextPage: any;
  keyword?: any;
}

export interface FilterRelationshipState {
  subject_id?: any;
  subject_namespace?: any;
  subject_object?: any;
  subject_relation?: any;
  namespace?: any;
  relation?: any;
  object?: any;
}

export interface FlagRelationshipState {
  flagRefresh?: boolean;
  flagRemove?: boolean;
  flagFilter?: boolean;
  flagStillFilter?: boolean;
  flagSearchBar?: boolean;
}

const initialState: ManageRelationshipState &
  FilterRelationshipState &
  FlagRelationshipState = {
  page: 0,
  limit: 10,
  total: 0,
  status: "loading",
  flagRefresh: false,
  arrPathNextPage: [""],
  flagRemove: false,
  keyword: "",
  flagFilter: false,
  flagStillFilter: false,
  flagSearchBar: false,

  subject_id: "",
  subject_namespace: "",
  subject_object: undefined,
  subject_relation: undefined,
  namespace: null,
  relation: undefined,
  object: undefined,
};

export const getListAsync = createAsyncThunk(
  "managerelationship/getList",
  async (payload) => {
    try {
      const response = await getList(payload);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const deleteRelationshipAsync = createAsyncThunk(
  "managerelationship/delRelationship",
  async (payload) => {
    try {
      const response = await delRelationship(payload);

      return response;
    } catch (e) {
      return {};
    }
  }
);

export const deleteMultipleRelationshipAsync = createAsyncThunk(
  "managerelationship/deleteMultipleRelationship",
  async (payload) => {
    try {
      const response = await deleteMultipleRelationship(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const addRelationshipAsync = createAsyncThunk(
  "managerelationship/addRelationship",
  async (payload) => {
    try {
      const response = await addRelationship(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);
export const addMultipleRelationshipAsync = createAsyncThunk(
  "managerelationship/addMultipleRelationship",
  async (payload) => {
    try {
      const response = await addMultipleRelationship(payload);
      return response;
    } catch (e) {
      return [];
    }
  }
);

export const ManageRelationshipSlice = createSlice({
  name: "managerelationship",
  initialState,
  reducers: {
    changeFlagRefresh: (state, action) => {
      state.flagRefresh = action?.payload;
    },
    changeArrPathNextPage: (state, action) => {
      state.arrPathNextPage = action.payload;
    },

    changeFlagRemove: (state, action) => {
      state.flagRemove = action.payload;
    },

    changeKeyword: (state, action) => {
      state.keyword = action.payload;
    },

    changeFlagSearchBar: (state, action) => {
      state.flagSearchBar = action.payload;
    },

    changeFlagFilter: (state, action) => {
      state.flagFilter = action.payload;
    },
    changeFlagStillFilter: (state, action) => {
      state.flagStillFilter = action.payload;
    },

    changeSubjectId: (state, action) => {
      state.subject_id = action.payload;
    },
    changeSubjectObject: (state, action) => {
      state.subject_object = action.payload;
    },
    changeSubjectNamespace: (state, action) => {
      state.subject_namespace = action.payload;
    },
    changeSubjectRelation: (state, action) => {
      state.subject_relation = action.payload;
    },
    changeNamespace: (state, action) => {
      state.namespace = action.payload;
    },
    changeRelation: (state, action) => {
      state.relation = action.payload;
    },
    changeObject: (state, action) => {
      state.object = action.payload;
    },

    resertFilterState: (state, action) => {
      state.subject_id = "";
      state.subject_namespace = "";
      state.subject_object = undefined;
      state.subject_relation = undefined;
      state.namespace = null;
      state.relation = undefined;
      state.object = undefined;
    },

    resetAllStateRelationship: (state, action) => {
      state.subject_id = "";
      state.subject_namespace = "";
      state.subject_object = undefined;
      state.subject_relation = undefined;
      state.namespace = null;
      state.relation = undefined;
      state.object = undefined;
      state.arrPathNextPage = [];
      state.flagStillFilter = false;
      state.flagFilter = false;
      state.keyword = false;
      state.flagRemove = false;
      state.flagRefresh = false;
      state.flagSearchBar = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      .addCase(addRelationshipAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addRelationshipAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(addMultipleRelationshipAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addMultipleRelationshipAsync.fulfilled, (state, action) => {
        state.status = "idle";
      })

      .addCase(deleteRelationshipAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteRelationshipAsync.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const {
  changeFlagRefresh,
  changeArrPathNextPage,
  changeFlagRemove,
  changeKeyword,
  changeFlagFilter,
  changeObject,
  changeRelation,
  changeNamespace,
  changeSubjectRelation,
  changeSubjectNamespace,
  changeSubjectObject,
  changeSubjectId,
  resertFilterState,
  changeFlagStillFilter,
  changeFlagSearchBar,
  resetAllStateRelationship,
} = ManageRelationshipSlice.actions;

export default ManageRelationshipSlice.reducer;
