import { Button, Divider } from "@fluentui/react-components";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderTitle,
} from "@fluentui/react-components/unstable";
import DismissSvg from "./../../assets/Dismiss.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { resetAllStateDrawer } from "../../store/DrawerSlice";
import RecoverySvg from "../../assets/recovery.svg";

import ApplySvg from "./../../assets/accept.svg";
import FilterSvg from "./../../assets/filter-footer.svg";
import RefreshSvg from "./../../assets/refresh.svg";
import SortSvg from "./../../assets/sortby.svg";
import FilterRelationshipComp from "../Relationship/FilterComp";
import {
  changeFlagFilter,
  changeFlagRefresh,
  changeFlagStillFilter,
  resertFilterState,
} from "../../store/ManageRelationship";

const DrawerComponent = (props: any) => {
  const { visibleRecovery, visibleFilter, visibleSort } = useSelector(
    (state: RootState) => state.drawer
  );

  const {
    subject_namespace,
    subject_object,
    subject_relation,
    flagStillFilter,
  } = useSelector((state: RootState) => state.managerelationship);

  const dispatch = useDispatch();

  const renderBodyDrawer = () => {
    if (visibleFilter) {
      return props?.pageName === "relationship/:id" ? (
        <FilterRelationshipComp />
      ) : null;
    } else if (visibleSort) {
      return props?.pageName === "relationship/:id" ? (
        <p>Thanh xuan sort</p>
      ) : null;
    }
  };

  const renderDrawerHeader = () => {
    if (visibleRecovery) {
      return (
        <div className="flex justify-between text-sm items-center">
          <div className="flex">
            <img className="!w-4" src={RecoverySvg} alt="Dismiss" />
            <span className="ml-1 font-thin"> Recovery</span>
          </div>
        </div>
      );
    }

    if (visibleFilter) {
      return (
        <div className="flex justify-between text-sm items-center">
          <div className="flex">
            <img src={FilterSvg} alt="Dismiss" />
            <span className="ml-1 font-thin"> Filter</span>
          </div>

          <div className="flex">
            <Button
              icon={
                <img
                  className="img-svg-icon"
                  src={ApplySvg}
                  alt="Info Circle"
                />
              }
              onClick={() => handleFilter("filter")}
              // appearance="transparent"
              className="btn-action-drawer !mr-2"
              disabled={
                props?.pageName === "relationship/:id" && checkDisabled()
              }
            >
              Apply
            </Button>
            <Button
              icon={
                <img
                  className="img-svg-icon"
                  src={RefreshSvg}
                  alt="Info Circle"
                />
              }
              className="btn-action-drawer"
              onClick={() => handleFilter("refresh")}
            />
          </div>
        </div>
      );
    } else if (visibleSort) {
      return (
        <div className="flex justify-between text-sm items-center">
          <div className="flex">
            <img src={SortSvg} alt="Dismiss" />
            <span className="ml-1 font-thin"> Sort</span>
          </div>

          <div className="flex">
            <Button
              icon={
                <img
                  className="img-svg-icon"
                  src={ApplySvg}
                  alt="Info Circle"
                />
              }
              // onClick={() => handleSort("sort")}
              // appearance="transparent"
              className="btn-action-drawer !mr-2"
            >
              Apply
            </Button>
            <Button
              icon={
                <img
                  className="img-svg-icon"
                  src={RefreshSvg}
                  alt="Info Circle"
                />
              }
              className="btn-action-drawer"
              // onClick={() => handleSort("refresh")}
            />
          </div>
        </div>
      );
    }
  };

  const handleFilter = (propsFun: any) => {
    if (propsFun === "filter") {
      if (props?.pageName === "relationship/:id") {
        dispatch(changeFlagFilter(true));
        dispatch(changeFlagStillFilter(true));
      }
    }

    if (propsFun === "refresh") {
      if (props?.pageName === "relationship/:id") {
        dispatch(resertFilterState({}));
        dispatch(changeFlagRefresh(true));
        dispatch(changeFlagStillFilter(false));
      }
    }
  };

  const checkDisabled = () => {
    let a = false;
    if (subject_namespace && (!subject_object || !subject_relation)) {
      a = true;
    } else if (subject_object && (!subject_namespace || !subject_relation)) {
      a = true;
    } else if (subject_relation && (!subject_namespace || !subject_object)) {
      a = true;
    }
    return a;
  };

  const handleCloseDrawer = () => {
    dispatch(resetAllStateDrawer({}));
    if (props?.pageName === "relationship/:id" && !flagStillFilter) {
      dispatch(resertFilterState({}));
    }
  };

  return (
    <Drawer
      className={"!bg-s-background1 drawer-cus !w-[35rem]"}
      onOpenChange={() => {
        dispatch(resetAllStateDrawer({}));
      }}
      type="inline"
      position="end"
      style={{
        ...props?.style,
      }}
      open={visibleFilter || visibleSort || visibleRecovery}
    >
      <DrawerHeader className="!bg-s-background1 !bg-none !px-6 !pt-3  z-10">
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<img src={DismissSvg} alt="Dismiss" />}
              onClick={() => {
                handleCloseDrawer();
              }}
              className="btn-action-drawer"
            />
          }
        >
          {renderDrawerHeader()}
        </DrawerHeaderTitle>
        <Divider></Divider>
      </DrawerHeader>

      <DrawerBody className="!bg-s-background1 !bg-none div-drawer-body">
        {renderBodyDrawer()}
      </DrawerBody>

      {props?.componentsFooter && (
        <DrawerFooter>{props?.componentsFooter}</DrawerFooter>
      )}
    </Drawer>
  );
};

export default DrawerComponent;
