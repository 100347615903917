import {
  Button as ButtonFlu,
  Checkbox,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Toast,
  ToastBody,
  ToastTitle,
  Tooltip,
  useToastController,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardItemIdentities from "../../components/Identities/CardItemIdentities";
import { RootState } from "../../store/store";
import EmptySvg from "./../../assets/nodata.svg";
import {
  changeFlagRecoverySelected,
  changeFlagRefresh,
  changeFlagRemoveSelected,
  createRecoveryLinkAsync,
  deleteUserAsync,
  getIdentitiesAsync,
  getListUserAsync,
} from "./../../store/ManageUserSlice";
import { changeTotal, resetState } from "./../../store/pagingSlice";

import DeleteSvg from "../../assets/delete.svg";
import RecoverySvg from "../../assets/recovery.svg";
import ReloadSvg from "../../assets/reload.svg";
import LoadingGlobalComp from "../../components/Loading/LoadingGlobal";
import LoadingListComp from "../../components/Loading/LoadingList";
import { changeDataBreadcrumb } from "../../store/BreadcrumbSlice";
import {
  changeStateBtnCreate,
  changeVisibleSpinner,
  changeVisibleToolAction,
} from "../../store/NavSlice";
import { changeName, changePath } from "../../store/pageSlice";
import {
  resetAllStateDrawer,
  toggleDrawerRecovery,
} from "../../store/DrawerSlice";

const timeout = 3000;

const dataBread = [
  {
    key: 1,
    value: "Identities",
  },
];

const ListIdentitiesPage = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [arrDel, setArrDel] = useState<any[]>([]);

  const { page, limit } = useSelector((state: RootState) => state.paging);
  const { flagRecoverySelected, flagRefresh, flagRemoveSelected } = useSelector(
    (state: RootState) => state.manageuser
  );
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  let toasterId: any = useSelector(
    (state: RootState) => state.notifications?.toasterId
  );

  const { dispatchToast } = useToastController(toasterId);
  useEffect(() => {
    (async function () {
      try {
        dispatch(changeVisibleSpinner(true));
        setLoading(true);
        // @ts-ignore

        const res = await dispatch(
          // @ts-ignore
          getIdentitiesAsync()
        ).unwrap();
        dispatch(changeTotal(res?.length));
        setData(
          res?.filter(
            (item: any, index: any) =>
              page * limit <= index && index < (page + 1) * limit
          ) ?? []
        );
        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      } catch (e) {
        console.error(e);
        dispatch(changeVisibleSpinner(false));
        setLoading(false);
      }
    })();
  }, [page]);

  useEffect(() => {
    if (flagRemoveSelected)
      (async function () {
        try {
          await deleteAll();
          dispatch(changeFlagRemoveSelected(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagRemoveSelected(false));
          setLoading(false);
        }
      })();
  }, [flagRemoveSelected]);

  useEffect(() => {
    if (flagRecoverySelected)
      (async function () {
        try {
          await recoverySelected();
          dispatch(changeFlagRecoverySelected(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagRecoverySelected(false));
          setLoading(false);
        }
      })();
  }, [flagRecoverySelected]);

  useEffect(() => {
    if (flagRefresh)
      (async function () {
        try {
          await refresh();
          dispatch(changeFlagRefresh(false));
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
          dispatch(changeFlagRefresh(false));
          setLoading(false);
        }
      })();
  }, [flagRefresh]);

  useEffect(() => {
    dispatch(
      changeStateBtnCreate({
        flag: true,
        path: "/identities/add",
      })
    );
    return () => {
      dispatch(changeStateBtnCreate({ flag: false, path: "" }));
      dispatch(changeVisibleToolAction(false));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/identities"));
    dispatch(changeName("identities"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
    };
  });

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
      // @ts-ignore
      dispatch(resetState());
      dispatch(resetAllStateDrawer({}));
    };
  }, []);

  const reloadData = async () => {
    const res = await dispatch(
      // @ts-ignore
      getListUserAsync({
        size: limit,
        page: page,
        count: true,
        sortby: "modified",
        asc: false,
      })
    ).unwrap();
    dispatch(changeTotal(res?.length));
    setData(
      res?.filter(
        (item: any, index: any) =>
          page * limit <= index && index < (page + 1) * limit
      ) ?? []
    );
  };

  const deleteUser = async (id: string) => {
    dispatch(changeVisibleSpinner(true));
    // @ts-ignore
    const res = await dispatch(deleteUserAsync(id)).unwrap();

    if (res?.status === 200 || res?.status === 204) {
      notify("Delete successfully", "success");
      reloadData();
      dispatch(changeVisibleSpinner(false));
    } else {
      notify("Delete fail", "error");
      dispatch(changeVisibleSpinner(false));
    }
  };

  const refresh = async () => {
    try {
      dispatch(changeVisibleSpinner(true));
      // @ts-ignore
      let payload = {
        size: limit,
        page: 0,
        count: true,
        sortby: "modified",
        asc: false,
      };
      const res = await dispatch(
        // @ts-ignore
        getIdentitiesAsync(payload)
      ).unwrap();
      dispatch(changeTotal(res?.length));
      setData(
        res?.filter(
          (item: any, index: any) =>
            page * limit <= index && index < (page + 1) * limit
        ) ?? []
      );
      dispatch(changeVisibleSpinner(false));
      notify("Refresh identities successfully", "success");
    } catch (e) {
      console.error(e);
      dispatch(changeVisibleSpinner(false));
      notify("Refresh identities successfully", "error");
    }
  };

  const notify = (result: any, type: any) => {
    return dispatchToast(
      <Toast>
        <ToastTitle>Notification</ToastTitle>
        <ToastBody>{result}</ToastBody>
      </Toast>,
      { timeout, intent: type }
    );
  };

  const pickItem = (item: any) => {
    let items = arrDel.slice(); // copy
    let index = items.findIndex((x) => x.id === item.id);
    if (index < 0) {
      items.push(item);
    } else {
      items.splice(index, 1);
    }
    setArrDel(items);

    if (items?.length > 0) {
      dispatch(changeVisibleToolAction(true));
    } else {
      dispatch(changeVisibleToolAction(false));
    }
  };

  const deleteAll = async () => {
    dispatch(changeVisibleSpinner(true));
    let b = arrDel;

    for (let i = 0; i < arrDel?.length; i++) {
      // @ts-ignore
      let res = await dispatch(deleteUserAsync(arrDel?.[i]?.id)).unwrap();

      if (res?.status === 204) {
        b = b?.filter((item: any) => item?.id !== arrDel?.[i]?.id);
        notify(
          `Delete ${arrDel?.[i]?.traits?.name?.first} ${arrDel?.[i]?.traits?.name?.last} successfully`,
          "success"
        );
      } else {
        notify(
          `Delete ${arrDel?.[i]?.traits?.name?.first} ${arrDel?.[i]?.traits?.name?.last} fail`,
          "error"
        );
      }
    }

    setArrDel(b);
    reloadData();
    dispatch(changeVisibleSpinner(false));
  };

  const recoverySelected = async () => {
    dispatch(changeVisibleSpinner(true));

    let result: any = [];

    for (let i = 0; i < arrDel?.length; i++) {
      let res = await dispatch(
        // @ts-ignore
        createRecoveryLinkAsync({ identity_id: arrDel?.[i]?.id })
      ).unwrap();

      if (res?.status === 200 || res?.status === 201) {
        notify(
          `Recovery ${arrDel?.[i]?.traits?.name?.first} ${arrDel?.[i]?.traits?.name?.last} successfully`,
          "success"
        );

        result.push({ identity: arrDel?.[i], result: res?.data });
      } else {
        notify(
          `Recovery ${arrDel?.[i]?.traits?.name?.first} ${arrDel?.[i]?.traits?.name?.last} fail`,
          "error"
        );

        result.push({ identity: arrDel?.[i] });
      }
    }

    dispatch(toggleDrawerRecovery(true));

    reloadData();
    dispatch(changeVisibleSpinner(false));
  };

  return (
    <div className="px-6 py-6 !w-full div-list !relative">
      {loading && <LoadingListComp />}

      {visibleSpinner && !loading && <LoadingGlobalComp />}

      {!data?.length && data?.length === 0 && !loading && (
        <center>
          <img src={EmptySvg} alt="empty" />
          <p className="mt-3 text-neutral-50">No data</p>
        </center>
      )}

      {data?.length !== 0 && (
        <>
          <div className="flex gap-1 mb-2">
            <Checkbox
              checked={
                arrDel?.length === data?.length
                  ? true
                  : arrDel?.length === 0
                  ? false
                  : "mixed"
              }
              onChange={(_ev, val) => {
                if (val?.checked) {
                  setArrDel(data);
                  dispatch(changeVisibleToolAction(true));
                } else {
                  setArrDel([]);
                  dispatch(changeVisibleToolAction(false));
                }
              }}
              label="Select all"
            />
          </div>
          <div style={{ marginTop: "5px" }}>
            <CardItemIdentities
              data={data}
              deleteUser={deleteUser}
              pickItem={pickItem}
              arrIdsDel={arrDel && arrDel.length ? arrDel.map((x) => x.id) : []}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ListIdentitiesPage;
