import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getList } from "./../services/ManageNamespace/ManageNamespace";

export interface ManageNamespaceState {
  page: number;
  limit: number;
  total: number;
  status: string;

  namespaces: Array<any>;
}

const initialState: ManageNamespaceState = {
  page: 0,
  limit: 10,
  total: 0,
  status: "loading",

  namespaces: []
};

export const getListAsync = createAsyncThunk(
  "managenamespace/getList",
  async (payload) => {
    try {
      const response = await getList(payload);
      return response.data;
    } catch (e) {
      return [];
    }
  }
);

export const ManageNamespaceSlice = createSlice({
  name: "managenamespace",
  initialState,
  reducers: {
    changeSate: (state, action) => {
      state.namespaces = action?.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getListAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.namespaces = action.payload?.namespaces ?? [];
      });
  },
});

export const { changeSate } = ManageNamespaceSlice.actions;

export default ManageNamespaceSlice.reducer;
