import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { changeDataBreadcrumb } from "../../../store/BreadcrumbSlice";
import { changeName, changePath } from "../../../store/pageSlice";
import { resetState } from "../../../store/pagingSlice";
import LoadingGlobalComp from "../../../components/Loading/LoadingGlobal";
import { RootState } from "../../../store/store";
import { changeVisibleSpinner } from "../../../store/NavSlice";
import { getInforUserByIdAsync } from "../../../store/ManageUserSlice";
import Editor from "@monaco-editor/react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

const ViewIdentitiesPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [value, setValue] = useState<any>(undefined);
  let { id } = useParams();
  const { visibleSpinner } = useSelector((state: RootState) => state.nav);
  const dataBread = [
    {
      key: 1,
      value: "Identities",
      path: "identities",
    },
    {
      key: 2,
      value:
        location?.state?.itemEdit?.traits?.name?.first &&
        location?.state?.itemEdit?.traits?.name?.last
          ? `View identities ${location?.state?.itemEdit?.traits?.name?.first} ${location?.state?.itemEdit?.traits?.name?.last}`
          : `View identities ${location?.state?.itemEdit?.traits?.email}`,
    },
  ];

  const customTheme = {
    base: "vs-dark",
    inherit: true,
    rules: [
      {
        token: "comment",
        foreground: "#5d7988",
        fontStyle: "italic",
      },
      { token: "constant", foreground: "#e06c75" },
    ],
    colors: {
      "editor.background": "#21252b",
    },
  };

  useEffect(() => {
    // @ts-ignore
    monaco.editor.defineTheme("my-custom-theme", customTheme);
    monaco.editor.setTheme("my-custom-theme");
  }, []);

  useEffect(() => {
    dispatch(changeDataBreadcrumb(dataBread));
    return () => {
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    dispatch(changePath("/identities/:id"));
    dispatch(changeName("identities/:id"));
    return () => {
      dispatch(changePath(null));
      dispatch(changeName(null));
      // @ts-ignore
      dispatch(resetState({}));
    };
  });

  useEffect(() => {
    (async function () {
      if (id)
        try {
          dispatch(changeVisibleSpinner(true));
          const res = await dispatch(
            // @ts-ignore
            getInforUserByIdAsync({ id: id })
          ).unwrap();

          if (res?.status === 200) {
            setValue(JSON.stringify(res?.data, null, 2));
          }
        } catch (e) {
          console.error(e);
          dispatch(changeVisibleSpinner(false));
        }
    })();
  }, [id]);

  const handleEditorChange = (value: any, event: any) => {
    // setValue(value);
    // event.preventDefault();
  };

  return (
    <div className="px-6 py-6 !w-full !relative">
      {visibleSpinner && <LoadingGlobalComp />}
      <Editor
        height="calc(100vh - 160px)"
        // width={"40%"}
        defaultLanguage="json"
        theme="my-custom-theme"
        onChange={handleEditorChange}
        onMount={() => {
          dispatch(changeVisibleSpinner(false));
        }}
        value={value}
        options={{ readOnly: true }}
      />
      {/* <div className="flex gap-8">
        
        <div>
          <p className="text-xl font-bold">Session</p>
        </div>
      </div> */}
    </div>
  );
};

export default ViewIdentitiesPage;
